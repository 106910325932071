import { fromJS, List } from 'immutable';

import {
    FETCH_DATA_SUCCESS,
} from './constants';

const initialState = fromJS({
    data: {},
});


function accountReducer(state = initialState, action) {
    switch (action.type) {
    case FETCH_DATA_SUCCESS:
        return state.set('data', fromJS(action.data));
    default:
        return state;
    }
}

export default accountReducer;
