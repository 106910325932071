export const LOGIN_USER = "cui/DashboardLoginPage/LOGIN_USER";
export const LOGIN_USER_SUCCESS = "cui/DashboardLoginPage/LOGIN_USER_SUCCESS";
export const COMPANY_CREATION = "cui/DashboardLoginPage/COMPANY_CREATION";
export const COMPANY_CREATION_SUCCESS =
    "cui/DashboardLoginPage/COMPANY_CREATION_SUCCESS";
export const SEND_OTP = "cui/DashboardLoginPage/SEND_OTP";
export const SEND_OTP_SUCCESS = "cui/DashboardLoginPage/SEND_OTP_SUCCESS";
export const RESEND_OTP = "cui/DashboardLoginPage/RESEND_OTP";
export const VERIFY_OTP = "cui/DashboardLoginPage/VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "cui/DashboardLoginPage/VERIFY_OTP_SUCCESS";
export const SEND_DISCORD_MESSAGE =
    "cui/DashboardLoginPage/SEND_DISCORD_MESSAGE";
