/*
 * ChatReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from "immutable";
import CryptoJS from "crypto-js";

import {
    INTERVIEW_DETAILS_SUCCESS,
    INTERVIEW_DETAILS_ERROR,
    FETCH_PREVIOUS_MESSAGES,
    FETCH_PREVIOUS_MESSAGES_SUCCESS,
    FETCH_PREVIOUS_MESSAGES_ERROR,
    FETCH_MESSAGES_SINCE,
    FETCH_MESSAGES_SINCE_SUCCESS,
    FETCH_MESSAGES_SINCE_ERROR,
    SEND_MESSAGES_SUCCESS,
    SEND_MESSAGES,
    SEND_MESSAGES_ERROR,
    ADD_MESSAGE_TO_BUFFER,
    UPDATE_DATASTORE,
    UPDATE_INTERVIEW,
    LATENCY_ERROR,
    UPDATE_DECRYPTIONKEY,
} from "./constants";
import handleGlobalActions from "../../utils/global/reducer";

// The initial state of the App
const initialState = fromJS({
    interviewDetails: {
        interviewId: null,
        data: null,
        interviewDetailsFetched: false,
        interviewDetailsFetchError: null,
    },
    messages: {
        data: [],
        error: null,
    },
    latencyError: false,
});

const workOnMessages = (exis, ne) => {
    const mIds = {};
    const mFIds = {};
    const mBIds = {};
    exis.map((m, i) => {
        if (m.get("id")) {
            mIds[m.get("id")] = "" + i;
        }
        const fId = (m.get("message") || fromJS({})).get("frontendId");
        const bId = (m.get("message") || fromJS({})).get("backendId");
        if (fId) {
            mFIds[fId] = "" + i;
        }
        if (bId) {
            mBIds[bId] = "" + i;
        }
    });
    (ne || []).map((x) => {
        if (!mIds[x.id]) {
            if (
                mFIds[(x.message || {}).frontendId] ||
                mBIds[(x.message || {}).backendId]
            ) {
                // it exists as frontend id.
                exis = exis.set(
                    Number(
                        mFIds[(x.message || {}).frontendId] ||
                            mBIds[(x.message || {}).backendId]
                    ),
                    fromJS(x)
                );
            } else {
                exis = exis.push(fromJS(x));
            }
        }
    });

    exis = exis.sort(
        (a, b) =>
            new Date(a.get("frontendTime") || a.get("time")).getTime() -
            new Date(b.get("frontendTime") || b.get("time")).getTime()
    );

    return exis;
};

function flowReducer(state = initialState, action) {
    state = handleGlobalActions(state, action);
    switch (action.type) {
        case LATENCY_ERROR:
            return state.set("latencyError", true);
        case UPDATE_INTERVIEW:
            return state.setIn(
                ["interviewDetails", "data", "interview", action.key],
                action.val
            );
        case UPDATE_DATASTORE: {
            var x = state
                .getIn([
                    "interviewDetails",
                    "data",
                    "interview",
                    "dtreeCurrentDataStore",
                    "v2Meta",
                ])
                .toJS();

            (action.updates || []).map((update) => {
                Object.keys(update?.v2Meta || {}).map((k) => {
                    x[k] = update?.v2Meta[k];
                });
            });

            return state.setIn(
                [
                    "interviewDetails",
                    "data",
                    "interview",
                    "dtreeCurrentDataStore",
                    "v2Meta",
                ],
                fromJS(x)
            );
        }
        case UPDATE_DECRYPTIONKEY: {
            const decryptQuestion = (q, key) => {
                if (q.get("decrypted")) {
                    return q;
                }
                const encryptedObject = q.toJS().question;

                const cipherParams = CryptoJS.lib.CipherParams.create({
                    ciphertext: CryptoJS.enc.Hex.parse(encryptedObject.data),
                });

                const decryptedData = CryptoJS.AES.decrypt(
                    cipherParams,
                    CryptoJS.enc.Hex.parse(key),
                    {
                        iv: CryptoJS.enc.Hex.parse(encryptedObject.iv),
                    }
                );

                // Convert the decrypted data to a UTF-8 string
                const decryptedString =
                    CryptoJS.enc.Utf8.stringify(decryptedData);

                return q
                    .set("question", fromJS(JSON.parse(decryptedString)))
                    .set("decrypted", true);
            };

            // Decrypt the question
            var x = state.getIn(["interviewDetails", "data", "questions"]);

            x = x.map((q) => {
                if (q.get("questionId") == action.questionId) {
                    return decryptQuestion(q, action.key);
                }
                return q;
            });

            return state.setIn(["interviewDetails", "data", "questions"], x);
        }
        case FETCH_PREVIOUS_MESSAGES_SUCCESS:
        case SEND_MESSAGES_SUCCESS:
        case FETCH_MESSAGES_SINCE_SUCCESS:
        case ADD_MESSAGE_TO_BUFFER:
            return state.setIn(
                ["messages", "data"],
                workOnMessages(
                    state.getIn(["messages", "data"]),
                    action.messages
                )
            );
        case SEND_MESSAGES:
            return state.setIn(
                ["messages", "data"],
                workOnMessages(
                    state.getIn(["messages", "data"]),
                    action.messages
                )
            );
        case FETCH_MESSAGES_SINCE_ERROR:
        case FETCH_PREVIOUS_MESSAGES_ERROR:
        case SEND_MESSAGES_ERROR:
            return state.setIn(["messages", "error"], action.error);
        case INTERVIEW_DETAILS_SUCCESS:
            return state
                .setIn(["interviewDetails", "interviewId"], action.interviewId)
                .setIn(["interviewDetails", "data"], fromJS(action.data))
                .setIn(["interviewDetails", "interviewDetailsFetched"], true)
                .setIn(
                    ["interviewDetails", "interviewDetailsFetchError"],
                    null
                );
        case INTERVIEW_DETAILS_ERROR:
            return state
                .setIn(["interviewDetails", "interviewDetailsFetched"], false)
                .setIn(
                    ["interviewDetails", "interviewDetailsFetchError"],
                    action.error
                );
        default:
            return state;
    }
}

export default flowReducer;
