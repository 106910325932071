/**
 * Gets the repositories of the user from Github
 */

import { call, put, select, takeEvery, throttle } from "redux-saga/effects";
import { delay } from "redux-saga";
import { fromJS } from "immutable";

import // loginUserSuccess,
"./actions";

import { SEND_DISCORD_MESSAGE } from "./constants";
import { sendDiscordAlert } from "../../shared/discord";

function* sendDiscordMessage(action) {
    const { message } = action;
    sendDiscordAlert({
        isDev: process.env.NODE_ENV !== "production",
        message,
        channel: "DISCORD_LOGS_GENERIC_ALERTS_WEBHOOK",
    });
}

export default function* rootSaga() {
    yield takeEvery(SEND_DISCORD_MESSAGE, sendDiscordMessage);
}
