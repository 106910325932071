export default function getAPIDomain() {
    // check if domain is tester.adaface.com
    if (window.location.hostname === "tester.adaface.com") {
        return "https://test-api.adaface.com";
    } else if (window.location.hostname === "app.adaface.com") {
        return "https://api.adaface.com";
    } else {
        return "http://localhost:3667";
    }
}
