import { all, fork } from "redux-saga/effects";

const context = require.context("../containers", true, /saga\.js$/);

function* rootSaga() {
    const sagas = [];

    context.keys().forEach((key) => {
        sagas.push(fork(context(key).default));
    });

    yield all(sagas);
}

export default rootSaga;
