import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    COMPANY_CREATION,
    COMPANY_CREATION_SUCCESS,
    SEND_DISCORD_MESSAGE,
    SEND_OTP,
    SEND_OTP_SUCCESS,
    VERIFY_OTP,
    VERIFY_OTP_SUCCESS,
    RESEND_OTP,
} from "./constants";

export function sendOtp({ emailId }) {
    return {
        type: SEND_OTP,
        emailId,
    };
}

export function sendOtpSuccess() {
    return {
        type: SEND_OTP_SUCCESS,
    };
}

export function resendOtp({ emailId }) {
    return {
        type: RESEND_OTP,
        emailId,
    };
}

export function verifyOtp({ emailId, code }) {
    return {
        type: VERIFY_OTP,
        emailId,
        code,
    };
}

export function verifyOtpSuccess({ emailId, token }) {
    return {
        type: VERIFY_OTP_SUCCESS,
        emailId,
        token,
    };
}

export function loginUser({ emailId, tokenId, env }) {
    return {
        type: LOGIN_USER,
        emailId,
        tokenId,
        env,
    };
}

export function loginUserSuccess({ companies }) {
    return {
        type: LOGIN_USER_SUCCESS,
        companies,
    };
}

export function createCompany({
    emailId,
    env,
    companyName,
    role,
    name,
    hiringGoal,
    companySize,
    learnAbout,
}) {
    return {
        type: COMPANY_CREATION,
        emailId,
        env,
        companyName,
        role,
        name,
        hiringGoal,
        companySize,
        learnAbout,
    };
}

export function companyCreationSuccess({ company }) {
    return {
        type: COMPANY_CREATION_SUCCESS,
        company,
    };
}

export function sendDiscordMessage({ message, channel }) {
    return {
        type: SEND_DISCORD_MESSAGE,
        message,
        channel,
    };
}
