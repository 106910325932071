import {
    FETCH_CURRENT_USER_EMAIL,
    FETCH_CURRENT_USER_EMAIL_SUCCESS,
} from "./constants";

export function fetchCurrentEmailId() {
    console.log("fetchCurrentEmailId actions");
    return {
        type: FETCH_CURRENT_USER_EMAIL,
    };
}

export function fetchCurrentEmailIdSuccess({ emailId }) {
    return {
        type: FETCH_CURRENT_USER_EMAIL_SUCCESS,
        emailId,
    };
}
