import "whatwg-fetch";

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response, getAdditionalData = false) {
    return new Promise((resolve, reject) => {
        if (response.status === 204 || response.status === 205) {
            resolve(null);
        }

        if (response.status === 304) {
            return resolve({
                status: response.status,
                headers: response.headers,
            });
        }

        response
            .json()
            .then((data) => {
                if (getAdditionalData) {
                    resolve({
                        status: response.status,
                        data,
                        headers: response.headers,
                    });
                } else {
                    resolve(data);
                }
            })
            .catch((error) => {
                resolve(null);
            });
    });
}

/**
 * Wraps a promise in a timeout
 * @param  {number} ms      The timeout limit
 * @param  {Promise} promise The promise to wrap
 * @return {Promise}         The promise that will reject after a timeout
 */
const timeout = (ms, promise) => {
    return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
            reject({ message: "Request timed out", code: "ETIMEDOUT" });
        }, ms);

        promise
            .then((value) => {
                clearTimeout(timer);
                resolve(value);
            })
            .catch((reason) => {
                clearTimeout(timer);
                reject(reason);
            });
    });
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
    if (
        (response.status >= 200 && response.status < 300) ||
        response.status === 304
    ) {
        return response;
    }

    const error = new Error(response.statusText);
    error.response = response;

    if (response.status === 400) {
        error.code = "ERR_BAD_RESPONSE";
    } else if (response.status === 500) {
        error.code = "ERR_NETWORK";
    } else if (response.status === 301) {
        error.code = "ERR_FR_TOO_MANY_REDIRECTS";
    } else if (response.status === 410) {
        error.code = "ERR_DEPRECATED";
    } else if (response.status === 408) {
        error.code = "ERR_CANCELED";
    }

    throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @param  {number} [timeoutMs] The timeout we want to set for the request
 *
 * @return {object}           The response data
 */
export default function request(
    url,
    options,
    timeoutMs = 40000,
    getAdditionalData = false
) {
    return timeout(
        timeoutMs,
        fetch(url, {
            withCredentials: true,
            credentials: "include",
            ...(options || {}),
            ...(options.body
                ? {
                      headers: {
                          "Content-Type": "application/json",
                          ...(options.headers || {}),
                      },
                  }
                : {}),
        })
            .then(checkStatus)
            .then((res) => parseJSON(res, getAdditionalData))
    );
}
