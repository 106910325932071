/**
 * Gets the repositories of the user from Github
 */

import { call, put, select, takeEvery, throttle } from "redux-saga/effects";
import { delay } from "redux-saga";
import { fromJS } from "immutable";

import request from "../../utils/request";
import { loginUserSuccess, sendOtpSuccess, verifyOtpSuccess } from "./actions";

import { LOGIN_USER, RESEND_OTP, SEND_OTP, VERIFY_OTP } from "./constants";

import getAPIDomain from "../../utils/domain";

function* sendOtp({ emailId }) {
    const { success, error } = yield call(() =>
        request(getAPIDomain() + "/app/api/login-system/send-otp", {
            method: "POST",
            body: JSON.stringify({
                emailId,
                interviewId:
                    ((window.location.search || "").split("/chat/") || [])
                        .length > 1 &&
                    !!((window.location.search || "").split("/chat/") || [])[1]
                        ? ((window.location.search || "").split("/chat/") ||
                              [])[1]
                        : "",
            }),
        })
    );
    if (success) {
        if (iziToast) {
            iziToast.success({
                position: "topRight",
                message:
                    "OTP sent to your email. Check your spam/trash folders as well.",
            });
        }

        yield put(sendOtpSuccess({ success }));
    } else {
        if (iziToast) {
            iziToast.error({
                position: "topRight",
                message: error || "Error while sending otp",
            });
        }
        yield put(
            sendOtpSuccess({
                success,
            })
        );
    }
}

function* resendOtp({ emailId }) {
    const { success, error } = yield call(() =>
        request(getAPIDomain() + "/app/api/login-system/resend-otp", {
            method: "POST",
            body: JSON.stringify({
                emailId,
            }),
        })
    );

    if (success) {
        if (iziToast) {
            iziToast.success({
                position: "topRight",
                message:
                    "OTP has been sent to your email again. Check your spam/trash folders as well.",
            });
        }
    } else {
        if (iziToast) {
            iziToast.error({
                position: "topRight",
                message: error || "Error while sending otp",
            });
        }
    }
}

function* verifyOtp({ emailId, code }) {
    const { result: token, error } = yield call(() =>
        request(getAPIDomain() + "/app/api/login-system/get-token", {
            method: "POST",
            body: JSON.stringify({
                emailId,
                code,
            }),
        })
    );

    if (!token || error) {
        // Throw error
        if (iziToast) {
            iziToast.error({
                message: "Invalid or expired OTP. Try again.",
                position: "topRight",
            });
        }
    } else {
        yield put(verifyOtpSuccess({ emailId, token }));
    }
}

export default function* rootSaga() {
    // yield takeEvery(LOGIN_USER, loginUser);
    yield takeEvery(SEND_OTP, sendOtp);
    yield takeEvery(RESEND_OTP, resendOtp);
    yield takeEvery(VERIFY_OTP, verifyOtp);
}
