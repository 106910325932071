/**
 * Gets the repositories of the user from Github
 */

import {
    call,
    put,
    select,
    takeEvery,
    throttle,
    all,
} from "redux-saga/effects";
import { delay } from "redux-saga";
import request from "../../utils/request";
import { GET_INTERVIEW_PUBLIC_DATA } from "./constants";
import { getInterviewPublicDataSuccess } from "./actions";
import getAPIDomain from "../../utils/domain";

function* getInterviewPublicDetails(action) {
    const { interviewId } = action;

    try {
        const url =
            getAPIDomain() + `/app/api/public-interview-data/${interviewId}`;

        const data = yield call(() =>
            request(url, {
                method: "POST",
            })
        );

        yield put(
            getInterviewPublicDataSuccess({
                interviewId,
                data,
            })
        );
    } catch (err) {
        console.log(err);
        // TODO: SHOW ERROR MESSAGE
        yield put(
            getInterviewPublicDataSuccess({
                interviewId,
                data: {},
            })
        );
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_INTERVIEW_PUBLIC_DATA, getInterviewPublicDetails);
}
