import { toast } from "react-toastify";

// Create toast notifications
export const sendToast = (msg, action) => {
	if (iziToast) {
		iziToast.success({
			title: "",
			message: msg,
			position: "topRight",
			// color: "#fcdc59"
		});
	} else {
		toast.success(msg, {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	}

	// toast(msg, {
	//     toastId: action, // Use action value as id
	//     autoClose: 3000,
	//     bodyClassName: "toast-content",
	//     hideProgressBar: true,
	// });
};

export const sendErrorToast = (msg, action) => {
	// toast(msg, {
	//     toastId: action,
	//     autoClose: 3000,
	//     bodyClassName: "toast-content has-text-danger",
	//     hideProgressBar: true,
	// });
	if (iziToast) {
		iziToast.warning({
			title: "",
			message: msg,
			position: "topRight",
			color: "#fcdc59",
		});
	} else {
		toast.error(msg, {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	}
};

export const updateToastDone = (msg, id) => {
	if (iziToast) {
		iziToast.success({
			title: "",
			message: msg,
			position: "topRight",
			// color: "#fcdc59"
		});
	}

	// toast.update(id, {
	//     render: msg,
	//     autoClose: 2500,
	// });
};
