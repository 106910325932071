import { call, put, takeEvery } from "redux-saga/effects";
import request from "../../utils/request";
import getAPIDomain from "../../utils/domain";
import { FETCH_CURRENT_USER_EMAIL } from "./constants";
import { fetchCurrentEmailIdSuccess } from "./actions";

function* fetchCurrentEmailId() {
    const currentUser = yield call(() =>
        request(getAPIDomain() + "/app/api/current_user", {
            method: "GET",
            headers: {},
        })
    );

    yield put(fetchCurrentEmailIdSuccess({ emailId: currentUser?.emailId }));
}

export default function* rootSaga() {
    yield takeEvery(FETCH_CURRENT_USER_EMAIL, fetchCurrentEmailId);
}
