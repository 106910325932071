// utils.js
import { call, put } from "redux-saga/effects";
import { requestErrorAction } from "./global/actions";
import { ERROR_CODES } from "./global/constants";

export default function* callWithErrorHandling(
    fn,
    isCriticalAPI = false,
    ...args
) {
    try {
        const response = yield call(fn, ...args);
        return response;
    } catch (error) {
        if (isCriticalAPI && ERROR_CODES[error?.code]) {
            yield put(requestErrorAction(error?.code));
        } else {
            throw error;
        }
    }
}
