/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
    GET_INTERVIEW_DETAILS,
    INTERVIEW_DETAILS_SUCCESS,
    INTERVIEW_DETAILS_ERROR,
    FETCH_PREVIOUS_MESSAGES,
    FETCH_PREVIOUS_MESSAGES_SUCCESS,
    FETCH_PREVIOUS_MESSAGES_ERROR,
    FETCH_MESSAGES_SINCE,
    FETCH_MESSAGES_SINCE_SUCCESS,
    FETCH_MESSAGES_SINCE_ERROR,
    SEND_MESSAGES,
    SEND_MESSAGES_SUCCESS,
    SEND_MESSAGES_ERROR,
    ADD_MESSAGE_TO_BUFFER,
    UPDATE_DATASTORE,
    UPDATE_INTERVIEW,
    LATENCY_ERROR,
    SEND_DISCORD_MESSAGE,
    UPDATE_DECRYPTIONKEY,
} from "./constants";

export function latencyError() {
    return {
        type: LATENCY_ERROR,
    };
}

export function updateInterview({ key, val }) {
    return {
        type: UPDATE_INTERVIEW,
        key,
        val,
    };
}

export function updateDataStore({ updates }) {
    return {
        type: UPDATE_DATASTORE,
        updates,
    };
}

export function updateDecryptionKey({ key, questionId }) {
    return {
        type: UPDATE_DECRYPTIONKEY,
        key,
        questionId,
    };
}

export function addMessagesToBuffer({ messages }) {
    return {
        type: ADD_MESSAGE_TO_BUFFER,
        messages,
    };
}

export function fetchPreviousMessages({ interviewId, time, limit, page }) {
    return {
        type: FETCH_PREVIOUS_MESSAGES,
        interviewId,
        time,
        limit,
        page,
    };
}

export function fetchPreviousMessagesSuccess({ messages }) {
    return {
        type: FETCH_PREVIOUS_MESSAGES_SUCCESS,
        messages,
    };
}

export function fetchPreviousMessagesError({ error }) {
    return {
        type: FETCH_PREVIOUS_MESSAGES_ERROR,
        error,
    };
}

export function fetchMessagesSince({ interviewId, time }) {
    return {
        type: FETCH_MESSAGES_SINCE,
        interviewId,
        time,
    };
}

export function fetchMessagesSinceSuccess({ messages }) {
    return {
        type: FETCH_MESSAGES_SINCE_SUCCESS,
        messages,
    };
}

export function fetchMessagesSinceError({ error }) {
    return {
        type: FETCH_MESSAGES_SINCE_ERROR,
        error,
    };
}

export function sendMessages({ messages, interviewId }) {
    return {
        type: SEND_MESSAGES,
        messages,
        interviewId,
    };
}

export function sendMessagesSuccess({ messages }) {
    return {
        type: SEND_MESSAGES_SUCCESS,
        messages,
    };
}

export function sendMessagesError({ error }) {
    return {
        type: SEND_MESSAGES_ERROR,
        error,
    };
}

export function getInterviewDetails({ interviewId, sessionId }) {
    return {
        type: GET_INTERVIEW_DETAILS,
        interviewId,
        sessionId,
    };
}

export function interviewDetails({ interviewId, data }) {
    return {
        type: INTERVIEW_DETAILS_SUCCESS,
        data,
        interviewId,
    };
}

export function interviewDetailsError(error) {
    return {
        type: INTERVIEW_DETAILS_ERROR,
        error,
    };
}

export function sendDiscordMessage({ message, channel, data }) {
    return {
        type: SEND_DISCORD_MESSAGE,
        message,
        channel,
        data,
    };
}
