/**
 * Gets the repositories of the user from Github
 */

import {
    call,
    put,
    select,
    takeEvery,
    throttle,
    all,
} from "redux-saga/effects";
import { delay } from "redux-saga";
import request from "../../utils/request";
import {
    GET_INTERVIEW_DETAILS,
    SEND_SLACK_MESSAGE,
    COMPILE_CODE,
    TOGGLE_INTERVIEW_END,
    REGISTER_CANDIDATE_DATA,
    SEND_INVITE_EMAIL,
    GET_INTERVIEW_QUESTIONS,
} from "./constants";
import {
    interviewDetails,
    interviewDetailsError,
    updateInterviewCodeDetails,
    compileCodeSuccess,
    getInterviewQuestionsSucess,
} from "./actions";
import { sendToast } from "../../utils/toaster";
import getAPIDomain from "../../utils/domain";

function* compileCode(action) {
    NProgress && NProgress.start();
    const { mode, code, stdin } = action;

    const data = {
        code,
        lang: mode,
        stdin,
    };

    const url = getAPIDomain() + "/app/api/code/execute";

    let result = {};

    try {
        result = yield call(() =>
            request(url, {
                method: "POST",
                body: JSON.stringify(data),
            })
        );
    } catch (err) {
        result = {
            stdout: "Code exceeded maximum running time!",
            stderr: "Code exceeded maximum running time!",
            error: "Code exceeded maximum running time!",
        };
    }

    yield put(compileCodeSuccess(result));
    NProgress && NProgress.done(true);
}

function* getInterviewDetails(action) {
    const { interviewId } = action;

    try {
        const url = getAPIDomain() + `/app/api/details-pair/${interviewId}`;

        const data = yield call(() =>
            request(url, {
                method: "POST",
            })
        );

        // if (!data.success) {
        // TODO: (redirect user back to adaface.com? convey that they don't have access to this page?)
        yield put(interviewDetailsError("Error fetching pair session details"));
        // } else {
        yield put(
            interviewDetails({
                interviewId,
                permissions: data.permissions,
            })
        );
        yield put(
            updateInterviewCodeDetails({
                code: interviewId,
                details: data.data || {},
            })
        );
        // }
    } catch (err) {
        console.log(err);
        // TODO: (redirect user back to adaface.com? convey that they don't have access to this page?)
        yield put(interviewDetailsError(err));
    }
}

function* sendSlackMessage(action) {
    let url =
        "https://hooks.slack.com/services/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";
    const { message, channel } = action;

    fetch(url, {
        method: "POST",
        body: JSON.stringify({ text: message }),
    });
}

function* toggleInterviewEnd({ code, endInterview }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/update_interview",
            {
                method: "POST",
                body: JSON.stringify({
                    interviewId: code,
                    endInterview,
                }),
            }
        )
    );
    const data2 = yield call(() =>
        getInterviewDetails({
            interviewId,
        })
    );
    NProgress && NProgress.done(true);
}

function* registerCandidateName({ name, interviewId }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/session-pair/register_candidate_data",
            {
                method: "POST",
                body: JSON.stringify({
                    interviewId,
                    name,
                }),
            }
        )
    );
    const data2 = yield call(() =>
        getInterviewDetails({
            interviewId,
        })
    );
    NProgress && NProgress.done(true);
}

function* sendInviteEmail({ email, interviewId }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/session-pair/send_invite_email",
            {
                method: "POST",
                body: JSON.stringify({
                    interviewId,
                    email,
                }),
            }
        )
    );
    sendToast("Email sent to candidate");
    const data2 = yield call(() =>
        getInterviewDetails({
            interviewId,
        })
    );
    NProgress && NProgress.done(true);
}

function* getInterviewQuestions({
    env,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
}) {
    NProgress && NProgress.start();

    // console.log("starting shit", Date.now());
    const data = yield call(() =>
        request(
            getAPIDomain() +
                `/app/api/integrations/dashboard/list_interview_questions`,
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    limit,
                    pageNo,
                    sortBy,
                    filters,
                }),
            }
        )
    );

    yield put(
        getInterviewQuestionsSucess({
            companyId,
            questions: data.questions,
        })
    );

    NProgress && NProgress.done();
}

export default function* rootSaga() {
    yield takeEvery(GET_INTERVIEW_DETAILS, getInterviewDetails);
    yield takeEvery(SEND_SLACK_MESSAGE, sendSlackMessage);
    yield takeEvery(COMPILE_CODE, compileCode);
    yield takeEvery(SEND_INVITE_EMAIL, sendInviteEmail);
    yield takeEvery(TOGGLE_INTERVIEW_END, toggleInterviewEnd);
    yield takeEvery(REGISTER_CANDIDATE_DATA, registerCandidateName);
    yield takeEvery(GET_INTERVIEW_QUESTIONS, getInterviewQuestions);
}
