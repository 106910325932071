/**
 * Gets the repositories of the user from Github
 */

import { put, takeEvery } from "redux-saga/effects";
import request from "../../utils/request";
import call from "../../utils/call";
import {
    GET_INTERVIEW_DETAILS,
    FETCH_PREVIOUS_MESSAGES,
    FETCH_MESSAGES_SINCE,
    SEND_MESSAGES,
    ERROR_CODES,
    SEND_DISCORD_MESSAGE,
} from "./constants";
import {
    interviewDetails,
    interviewDetailsError,
    fetchPreviousMessagesSuccess,
    fetchPreviousMessagesError,
    fetchMessagesSinceSuccess,
    fetchMessagesSinceError,
    sendMessagesSuccess,
    sendMessagesError,
    latencyError,
} from "./actions";
import { serverTimeCheck, versionCheck } from "./checkers";
import axios from "axios";
const axiosInstance = axios.create({
    timeout: 40000,
    withCredentials: true,
});

import getAPIDomain from "../../utils/domain";
import { sendDiscordAlert } from "../../shared/discord";

const flowDomain =
    window.location.href.indexOf("localhost") > -1
        ? "localhost:3600"
        : window.location.href.indexOf("tester") > -1
        ? "test-flow.adaface.com"
        : "flow.adaface.com";

function* serverTimeMiddleware(serverTime) {
    const { success } = serverTimeCheck(serverTime);
    if (!success) {
        yield put(latencyError());
        // if there is more than 2 minute difference, show major alert.
        // What this also might mean is that there are some APIs with insane delay which is not good.
    }
}

function* versionMiddleware(version) {
    const { success } = versionCheck(version);
    if (!success) {
        window.location.reload();
    }
}

function* sendDiscordMessage(action) {
    let discordChannel = "DISCORD_LOGS_GENERIC_ALERTS_WEBHOOK";
    const { message, channel, data } = action;

    if (channel === "logs-test-requests") {
        discordChannel = "DISCORD_LOGS_TEST_REQUESTS_WEBHOOK";
    }

    if (channel === "logs-companies-core") {
        discordChannel = "DISCORD_LOGS_COMPANIES_CORE_WEBHOOK";
    }

    if (channel === "logs-talk-requests") {
        discordChannel = "DISCORD_LOGS_TALK_REQUESTS_WEBHOOK";
    }

    if (channel === "logs-companies-plans") {
        discordChannel = "DISCORD_LOGS_COMPANIES_PLANS_WEBHOOK";
    }

    if (channel === "logs-preview-test") {
        discordChannel = "DISCORD_LOGS_PREVIEW_TEST_WEBHOOK";
    }

    if (channel === "logs-adaface-alerts") {
        discordChannel = "DISCORD_ALERTS_WEBHOOK";
    }

    if (channel === "logs-adaface-errors") {
        discordChannel = "DISCORD_ERRORS_WEBHOOK";
    }

    sendDiscordAlert({
        isDev: process.env.NODE_ENV !== "production",
        message,
        data,
        channel: discordChannel,
    });
}

function* getInterviewDetails(action) {
    const { interviewId, sessionId } = action;

    try {
        const url = `//${flowDomain}/interview/details`;
        const initUrl =
            getAPIDomain() + `/app/api/interview-start/${interviewId}`;

        const dummy = yield call(() => {
            axiosInstance.request({
                url: initUrl,
                method: "POST",
            });
        });

        let d = {
            interviewId,
            sessionId,
        };

        if (window.location.href.indexOf("agent") !== -1) {
            // TODO: (SG) replace with better logic
            d["agent"] = true;
        }

        const data = yield call(
            () =>
                axiosInstance.request({
                    url,
                    method: "POST",
                    data: d,
                }),
            true
        );

        const {
            success,
            error,
            interview,
            test,
            questions,
            serverTime,
            faqs,
            isFAQsEnabled,
            introOverride,
            chatLanguageOverride,
            nameOverride,
            phoneOverride,
            version,
            loginRequired,
            mismatchedEmailId,
        } = (data || {}).data || {};

        if (loginRequired) {
            if (mismatchedEmailId) {
                if (iziToast) {
                    iziToast.error({
                        position: "topRight",
                        message:
                            "Please login using exact same email address that you received the invite with. In case you are stuck, feel free to contact us at ada@adaface.com and we will help you out :)",
                    });
                }
            }

            return setTimeout(() => {
                window.location = `${
                    window.location.hostname.indexOf("localhost") !== -1
                        ? "http://localhost:3666"
                        : "https://app.adaface.com"
                }/app/candidate/login?redirectUrlAdaface=${encodeURI(
                    window.location.href
                )}`;

                return;
            }, 5000);
        }

        if (success && !error) {
            yield call(() => serverTimeMiddleware(serverTime));
            yield call(() => versionMiddleware(version));
            yield put(
                interviewDetails({
                    interviewId,
                    data: {
                        interview,
                        test,
                        questions,
                        faqs: faqs || [],
                        isFAQsEnabled: isFAQsEnabled || false,
                        introOverride,
                        nameOverride,
                        phoneOverride,
                        chatLanguageOverride
                    },
                })
            );
        } else {
            yield put(
                interviewDetailsError(
                    (error || "Unable to fetch interview details") +
                        ". Make sure your internet connection quality is good and reload."
                )
            );
        }
    } catch (err) {
        yield put(
            interviewDetailsError(
                ERROR_CODES[(err || {}).code]
                    ? (err || {}).code
                    : ((err || {}).message ||
                          "Unable to fetch interview details") +
                          ". Make sure your internet connection quality is good and reload."
            )
        );
    }
}

export function* fetchPreviousMessages({
    interviewId,
    page = 1,
    time,
    limit = 50,
}) {
    try {
        const url = `//${flowDomain}/messages/before`;

        const data = yield call(() =>
            request(url, {
                method: "POST",
                body: JSON.stringify({
                    interviewId,
                    page,
                    time,
                }),
                credentials: "omit",
                withoutCredentials: true,
            })
        );

        const {
            success,
            error,
            messages: newMessages,
            version,
            serverTime,
        } = data || {};

        yield call(() => serverTimeMiddleware(serverTime));
        yield call(() => versionMiddleware(version));

        if (success && !error) {
            yield put(fetchPreviousMessagesSuccess({ messages: newMessages }));
            if (newMessages.length >= 50) {
                yield call(() =>
                    fetchPreviousMessages({
                        time: newMessages[newMessages.length - 1].time,
                        interviewId,
                        limit,
                        page,
                    })
                );
            }
        } else {
            yield put(fetchPreviousMessagesError({ error }));
        }
    } catch (err) {
        console.log(err);
        yield put(fetchPreviousMessagesError({ error: err }));
    }
}

export function* fetchMessagesSince({ time, interviewId }) {
    try {
        const url = `//${flowDomain}/messages/after`;

        const data = yield call(() =>
            request(url, {
                method: "POST",
                body: JSON.stringify({
                    interviewId,
                    time,
                }),
                credentials: "omit",
                withoutCredentials: true,
            })
        );

        const {
            success,
            error,
            messages: newMessages,
            version,
            serverTime,
        } = data || {};

        yield call(() => serverTimeMiddleware(serverTime));
        yield call(() => versionMiddleware(version));

        if (success && !error) {
            yield put(fetchMessagesSinceSuccess({ messages: newMessages }));
        } else {
            yield put(fetchMessagesSinceError({ error }));
        }
    } catch (err) {
        console.log(err);
        yield put(fetchMessagesSinceError({ error: err }));
    }
}

export function* sendMessages({ messages, interviewId }) {
    try {
        const url = `//${flowDomain}/messages/add`;

        const data = yield call(() =>
            request(url, {
                method: "POST",
                body: JSON.stringify({
                    messages,
                    interviewId,
                }),
            })
        );

        const {
            success,
            error,
            messages: newMessages,
            version,
            serverTime,
        } = data || {};

        yield call(() => serverTimeMiddleware(serverTime));
        yield call(() => versionMiddleware(version));

        if (success && !error) {
            yield put(sendMessagesSuccess({ messages: newMessages }));
        } else {
            yield put(sendMessagesError({ error }));
        }
    } catch (err) {
        console.log(err);
        yield put(sendMessagesError({ error: err }));
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_INTERVIEW_DETAILS, getInterviewDetails);
    yield takeEvery(FETCH_PREVIOUS_MESSAGES, fetchPreviousMessages);
    yield takeEvery(FETCH_MESSAGES_SINCE, fetchMessagesSince);
    yield takeEvery(SEND_MESSAGES, sendMessages);
    yield takeEvery(SEND_DISCORD_MESSAGE, sendDiscordMessage);
}
