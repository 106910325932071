/**
 * Gets the repositories of the user from Github
 */

import {
    call,
    put,
    select,
    takeEvery,
    throttle,
    all,
    delay,
} from "redux-saga/effects";
import request from "../../utils/request";
import { GET_INTERVIEW_PUBLIC_DATA } from "./constants";
import { getInterviewPublicDataSuccess } from "./actions";

import getAPIDomain from "../../utils/domain";

function* getInterviewPublicDetails(action) {
    const { interviewId } = action;

    let data;

    try {
        const url =
            getAPIDomain() + `/app/api/public-interview-data/${interviewId}`;

        data = yield call(() =>
            request(url, {
                method: "POST",
            })
        );

        yield put(
            getInterviewPublicDataSuccess({
                interviewId,
                data,
            })
        );
    } catch (err) {
        console.log(err);
        // TODO: SHOW ERROR MESSAGE
        yield put(
            getInterviewPublicDataSuccess({
                interviewId,
                data: {},
            })
        );
    }

    if (
        data &&
        data.applicationStatusType === "scorecard" &&
        (!data.publicScorecardLink || !data.interviewEndTime)
    ) {
        // max 10 calls
        window.RETRY_CALL = window.RETRY_CALL || 0;
        window.RETRY_CALL += 1;

        if (window.RETRY_CALL < 10) {
            yield delay(10000);
            yield call(() => getInterviewPublicDetails(action));
        }
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_INTERVIEW_PUBLIC_DATA, getInterviewPublicDetails);
}
