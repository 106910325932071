export const FETCH_SCORECARD = "cui/Scorecard/FETCH_SCORECARD";
export const FETCH_SCORECARD_SUCCESS = "cui/Scorecard/FETCH_SCORECARD_SUCCESS";
export const FETCH_SCORECARD_FAILURE = "cui/Scorecard/FETCH_SCORECARD_FAILURE";
export const FETCH_SCORECARD_INTERVIEW_DATA =
	"cui/Scorecard/FETCH_SCORECARD_INTERVIEW_DATA";
export const FETCH_SCORECARD_INTERVIEW_DATA_SUCCESS =
	"cui/Scorecard/FETCH_SCORECARD_INTERVIEW_DATA_SUCCESS";

export const FETCH_SCORECARD_IP_DETAILS =
	"cui/Scorecard/FETCH_SCORECARD_IP_DETAILS";
export const FETCH_SCORECARD_IP_DETAILS_SUCCESS =
	"cui/Scorecard/FETCH_SCORECARD_IP_DETAILS_SUCCESS";

export const SEND_DISCORD_MESSAGE = "cui/Scorecard/SEND_DISCORD_MESSAGE";
export const COMPILE_CODE = "cui/Scorecard/COMPILE_CODE";
export const COMPILE_CODE_SUCCESS = "cui/Scorecard/COMPILE_CODE_SUCCESS";
