import { fromJS, List } from "immutable";

import { FETCH_CURRENT_USER_EMAIL_SUCCESS } from "./constants";

const initialState = fromJS({
    emailId: "",
});

function notFoundPageReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CURRENT_USER_EMAIL_SUCCESS:
            return state.set("emailId", action.emailId);
        default:
            return state;
    }
}

export default notFoundPageReducer;
