import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store/configureStore";
import { Provider } from "react-redux";

// Import all the third party stuff
import { CookiesProvider } from "react-cookie";
import "sanitize.css/sanitize.css";

import App from "./containers/App";

import { init, captureMessage } from "@sentry/browser";
import { Worker } from "@react-pdf-viewer/core";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleUp,
    faAngleDown,
    faDotCircle,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCircleInfo,
    faEye,
    faExclamationTriangle,
    faCheckSquare,
    faExpandArrowsAlt,
    faAngleDoubleDown,
    faUpload,
    faCheck,
    faExclamation,
    faCheckDouble,
    faMagic,
    faVials,
    faVial,
    faUsers,
    faBookReader,
    faPoll,
    faTable,
    faUserCog,
    faPaperPlane,
    faChartPie,
    faTimes,
    faHistory,
    faPlayCircle,
    faPlay,
    faFileVideo,
    faArrowsAlt,
    faEyeSlash,
    faPhoneSlash,
    faStar,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faPencilAlt,
    faEdit,
    faSync,
    faQuestionCircle,
    faArrowUpFromBracket,
    faXmarkCircle,
    faXmark,
    faCreditCard,
    faPen,
    faArrowUpRightFromSquare,
    faCircleArrowUp,
    faRuler,
    faAnglesRight,
    faLocationDot,
    faCircleCheck,
    faGripVertical,
    faTableList,
    faGear,
    faStarHalfStroke,
    faCartShopping,
    faBolt,
    faBell,
    faTriangleExclamation,
    faEnvelope,
    faCircleStop,
    faForwardFast,
    faThumbsDown,
    faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import {
    faCheckCircle,
    faCheckSquare as faeCheckSquare2,
    faSquare,
    faEye as faEye2,
    faTrashAlt,
    faTrashCan,
    faFilePdf,
    faStar as faStar2,
    faCopy,
    faStopCircle,
    faCircleXmark,
    faQuestionCircle as faQuestionCircle2,
    faPenToSquare,
    faMessage,
} from "@fortawesome/free-regular-svg-icons";

library.add(
    faLocationDot,
    faCircleCheck,
    faCircleArrowUp,
    faPenToSquare,
    faPaperPlane,
    faAngleUp,
    faAngleDown,
    faDotCircle,
    faCheckCircle,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCircleInfo,
    faPlay,
    faStopCircle,
    faEye,
    faTrashAlt,
    faEye2,
    faExclamationTriangle,
    faCheckSquare,
    faeCheckSquare2,
    faSquare,
    faExpandArrowsAlt,
    faAngleDoubleDown,
    faUpload,
    faCheck,
    faExclamation,
    faCheckDouble,
    faMagic,
    faUsers,
    faCopy,
    faBookReader,
    faPoll,
    faTable,
    faVial,
    faEdit,
    faPencilAlt,
    faVials,
    faUserCog,
    faChartPie,
    faFilePdf,
    faTimes,
    faHistory,
    faPlayCircle,
    faFileVideo,
    faArrowsAlt,
    faEyeSlash,
    faPhoneSlash,
    faStar,
    faStar2,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faSync,
    faQuestionCircle,
    faArrowUpFromBracket,
    faArrowUpRightFromSquare,
    faXmarkCircle,
    faXmark,
    faTrashCan,
    faCreditCard,
    faCircleXmark,
    faPen,
    faQuestionCircle2,
    faRuler,
    faAnglesRight,
    faMessage,
    faGripVertical,
    faTableList,
    faStarHalfStroke,
    faGear,
    faCartShopping,
    faBolt,
    faBell,
    faTriangleExclamation,
    faEnvelope,
    faCircleStop,
    faForwardFast,
    faThumbsDown,
    faThumbsUp
);

// SENTRY
if (
    process.env.NODE_ENV == "production" &&
    process.env.SUBDOMAIN !== "tester"
) {
    init({
        dsn: "https://5a2f49162a834652b040988596488a3b@sentry.io/1301857",
    });
}

ReactDOM.render(
    <CookiesProvider>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.10.111/build/pdf.worker.min.js">
            <Provider store={store}>
                <App />
            </Provider>
        </Worker>
    </CookiesProvider>,
    document.getElementById("app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
