import {
	GET_INTERVIEW_PUBLIC_DATA,
	GET_INTERVIEW_PUBLIC_DATA_SUCCESS,
} from "./constants";

export function getInterviewPublicData({ interviewId }) {
	return {
		interviewId,
		type: GET_INTERVIEW_PUBLIC_DATA,
	};
}

export function getInterviewPublicDataSuccess({ interviewId, data }) {
	return {
		interviewId,
		type: GET_INTERVIEW_PUBLIC_DATA_SUCCESS,
		data,
	};
}
