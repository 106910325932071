// src/Auth/Auth.js

import request from "./request";

export default class Proctoring {
    constructor(env) {
        this.subscribers = [];
    }

    setup() {
        this.interval = setInterval(() => {
            const currentFocus = document.hasFocus();
            const previousFocus = this.focus;
            if (this.firstTimeFocusValue) {
                if (previousFocus !== currentFocus) {
                    // change in focus
                    this.focus = currentFocus;
                    this.subscribers.map((x) => x(this.focus));
                }
            } else if (currentFocus) {
                this.firstTimeFocusValue = true;
                this.focus = currentFocus;
                this.subscribers.map((x) => x(this.focus, true));
            } else {
                // this case shouldn't happen. so ignore for now.
            }
        }, 300);
    }

    subscribe(callback) {
        this.subscribers.push(callback);
        if (!this.interval) {
            this.setup();
        }
        return () => {
            this.subscribers.splice(this.subscribers.indexOf(callback), 1);
            if (this.subscribers.length === 0) {
                this.stop();
            }
        };
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    hasFocus() {
        return !!this.focus;
    }

    stop() {
        this.interval && clearInterval(this.interval);
        this.interval = null;
    }
}
