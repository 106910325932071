/**
 * Gets the repositories of the user from Github
 */

import { call, put, select, takeEvery, throttle } from "redux-saga/effects";
import { delay } from "redux-saga";
import { fromJS } from "immutable";
import "whatwg-fetch";

import { sendDiscordAlert } from "../../shared/discord";
import request from "../../utils/request";
import {
    loginUserSuccess,
    companyCreationSuccess,
    sendOtpSuccess,
    verifyOtpSuccess,
} from "./actions";

import {
    LOGIN_USER,
    COMPANY_CREATION,
    SEND_OTP,
    VERIFY_OTP,
    RESEND_OTP,
    SEND_DISCORD_MESSAGE,
} from "./constants";

import Events from "../../utils/events";

import getAPIDomain from "../../utils/domain";

function getQueryStringValue(key) {
    return decodeURIComponent(
        window.location.search.replace(
            new RegExp(
                "^(?:.*[&\\?]" +
                    encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
                    "(?:\\=([^&]*))?)?.*$",
                "i"
            ),
            "$1"
        )
    );
}

function* loginUser(action) {
    const { env, emailId, tokenId } = action;
    let companies = yield call(() =>
        request(getAPIDomain() + "/app/api/user/companies", {
            method: "POST",
            body: JSON.stringify({
                emailId,
            }),
        })
    );

    if (
        ((companies || {}).activeCompanies || []).length === 0 &&
        ((companies || {}).deactiveCompanies || []).length === 0
    ) {
        // frontend will go through onboarding flow
        yield put(
            loginUserSuccess({
                companies: [],
            })
        );
    } else if (((companies || {}).activeCompanies || []).length > 0) {
        // redirectus maximus
        Events.sendAction({
            action: Events.ACTIONS.GENERAL,
            metadata: {
                companyId: companies.activeCompanies[0].companyId,
            },
            page: Events.PAGES.DASHBOARD_LOGIN_PAGE,
            name: "signup_success",
        });
        window.location = `/app/dashboard/${
            companies.activeCompanies[0].companyId
        }?preview-test-id=${getQueryStringValue("preview-test-id")}&newuser=1`;
        yield put(
            loginUserSuccess({
                companies: companies.activeCompanies,
            })
        );
    } else if (((companies || {}).deactiveCompanies || []).length > 0) {
        Events.sendAction({
            action: Events.ACTIONS.GENERAL,
            metadata: {
                companyId: companies.deactiveCompanies[0].companyId,
            },
            page: Events.PAGES.DASHBOARD_LOGIN_PAGE,
            name: "signup_success",
        });
        window.location = `/app/dashboard/${
            companies.deactiveCompanies[0].companyId
        }?preview-test-id=${getQueryStringValue("preview-test-id")}&newuser=1`;
        yield put(
            loginUserSuccess({
                companies: companies.deactiveCompanies,
            })
        );
    } else {
        // TODO: frontend to show what account they want to select
        // window.location = "/app/dashboard/" + companies[0].companyId;
        yield put(
            loginUserSuccess({
                companies: [],
            })
        );
    }
}

function* sendOtp({ emailId }) {
    const { success, error } = yield call(() =>
        request(getAPIDomain() + "/app/api/login-system/send-otp", {
            method: "POST",
            body: JSON.stringify({
                emailId,
            }),
        })
    );

    // if (iziToast) {
    // 	iziToast.success({
    // 		position: "topRight",
    // 		message:
    // 			"OTP sent to your email. Check your spam/trash folders as well.",
    // 	});
    // }

    yield put(sendOtpSuccess());
}

function* resendOtp({ emailId }) {
    const { success, error } = yield call(() =>
        request(getAPIDomain() + "/app/api/login-system/resend-otp", {
            method: "POST",
            body: JSON.stringify({
                emailId,
            }),
        })
    );
}

function* verifyOtp({ emailId, code }) {
    const { result: token, error } = yield call(() =>
        request(getAPIDomain() + "/app/api/login-system/get-token", {
            method: "POST",
            body: JSON.stringify({
                emailId,
                code,
            }),
        })
    );

    if (!token || error) {
        // Throw error
        if (iziToast) {
            iziToast.error({
                message: "Invalid or expired OTP. Try again.",
                position: "topRight",
            });
        }
    } else {
        yield put(verifyOtpSuccess({ emailId, token }));
    }
}

function* createCompany(action) {
    const {
        emailId,
        env,
        companyName,
        role,
        name,
        hiringGoal,
        companySize,
        learnAbout,
    } = action;

    const { companyId } = yield call(() =>
        request(getAPIDomain() + "/app/api/company/new", {
            method: "POST",
            body: JSON.stringify({
                companyName,
                emailId,
                name,
                role,
                hiringGoal,
                companySize,
                learnAbout,
                env,
                ...(getQueryStringValue("userId")
                    ? { resumeCookie: getQueryStringValue("userId") }
                    : {}),
                ...(getQueryStringValue("preview-test-id")
                    ? {
                          convertedPreviewTestId:
                              getQueryStringValue("preview-test-id"),
                      }
                    : {}),
            }),
        })
    );

    // TODO: Do we delete resumeCookie from localStorage?

    const rand = yield call(() => loginUser({ emailId }));

    yield put(
        companyCreationSuccess({
            company: {
                companyId,
            },
        })
    );
}

function* sendDiscordMessage(action) {
    let discordChannel = "DISCORD_LOGS_GENERIC_ALERTS_WEBHOOK";
    const { message, channel, data } = action;

    if (channel === "logs-adaface-alerts") {
        discordChannel = "DISCORD_ALERTS_WEBHOOK";
    }

    if (channel === "logs-companies-core") {
        discordChannel = "DISCORD_LOGS_COMPANIES_CORE_WEBHOOK";
    }

    sendDiscordAlert({
        isDev:
            process.env.NODE_ENV !== "production" ||
            process.env.SUBDOMAIN === "tester",
        message,
        channel: discordChannel,
        data,
    });
}

export default function* rootSaga() {
    yield takeEvery(SEND_OTP, sendOtp);
    yield takeEvery(VERIFY_OTP, verifyOtp);
    yield takeEvery(LOGIN_USER, loginUser);
    yield takeEvery(COMPANY_CREATION, createCompany);
    yield takeEvery(RESEND_OTP, resendOtp);
    yield takeEvery(SEND_DISCORD_MESSAGE, sendDiscordMessage);
}
