import { fromJS, List } from "immutable";

import {
	FETCH_SCORECARD,
	FETCH_SCORECARD_SUCCESS,
	FETCH_SCORECARD_FAILURE,
	COMPILE_CODE,
	COMPILE_CODE_SUCCESS,
	FETCH_SCORECARD_IP_DETAILS_SUCCESS,
	FETCH_SCORECARD_INTERVIEW_DATA_SUCCESS,
	FETCH_SCORECARD_INTERVIEW_DATA,
	FETCH_SCORECARD_IP_DETAILS,
} from "./constants";

const initialState = fromJS({
	scorecards: {},
	currentInterviewId: "",
	currentViewKey: "",
	glotResults: {},
	accountDeactivated: false,
});

function scorecardPageReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_SCORECARD:
			return state
				.setIn(
					[
						"scorecards",
						action.interviewId,
						action.viewKey,
						"status",
					],
					"loading"
				)
				.setIn(["currentInterviewId"], action.interviewId)
				.setIn(["currentViewKey"], action.viewKey);

		case FETCH_SCORECARD_FAILURE:
			return state.setIn(
				["scorecards", action.interviewId, action.viewKey, "status"],
				"failure"
			);
		case FETCH_SCORECARD_SUCCESS:
			return state
				.setIn(
					[
						"scorecards",
						action.interviewId,
						action.viewKey,
						"status",
					],
					"success"
				)
				.setIn(
					["scorecards", action.interviewId, action.viewKey, "data"],
					fromJS(action.scorecardData)
				);
		case FETCH_SCORECARD_IP_DETAILS:
			return state
				.setIn(["currentInterviewId"], action.interviewId)
				.setIn(["currentViewKey"], action.viewKey)
				.setIn(
					[
						"scorecards",
						action.interviewId,
						action.viewKey,
						"ipProctoringDetails",
						"status",
					],
					"loading"
				);
		case FETCH_SCORECARD_IP_DETAILS_SUCCESS:
			return state.setIn(
				[
					"scorecards",
					action.interviewId,
					action.viewKey,
					"ipProctoringDetails",
				],
				fromJS({
					status: "success",
					data: action.ipProctoringDetails,
				})
			);
		case FETCH_SCORECARD_INTERVIEW_DATA:
			return state
				.setIn(["currentInterviewId"], action.interviewId)
				.setIn(["currentViewKey"], action.viewKey)
				.setIn(
					[
						"scorecards",
						action.interviewId,
						action.viewKey,
						"interviewData",
						"status",
					],
					"loading"
				);
		case FETCH_SCORECARD_INTERVIEW_DATA_SUCCESS:
			return state
				.set("accountDeactivated", !!action.accountDeactivated)
				.setIn(
					[
						"scorecards",
						action.interviewId,
						action.viewKey,
						"interviewData",
					],
					fromJS({ status: "success", data: action.interviewData })
				)
				.setIn(
					[
						"scorecards",
						action.interviewId,
						action.viewKey,
						"status",
					],
					fromJS({ status: "success", data: action.status })
				);
		case COMPILE_CODE:
			return state.setIn(
				[
					"glotResults",
					action.interviewId,
					action.index,
					"compilationStatus",
				],
				true
			);
		case COMPILE_CODE_SUCCESS:
			const currentResults =
				state.getIn([
					"glotResults",
					action.interviewId,
					action.index,
				]) || fromJS({});
			return state.setIn(
				["glotResults", action.interviewId, action.index],
				currentResults
					.set("glotstdout", action.glotstdout)
					.set("glotstderr", action.glotstderr)
					.set("gloterror", action.gloterror)
					.set("compilationStatus", false)
			);
		default:
			return state;
	}
}

export default scorecardPageReducer;
