/*
 * ChatReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from "immutable";

import {
    INTERVIEW_DETAILS_SUCCESS,
    INTERVIEW_DETAILS_ERROR,
    COMPILE_CODE,
    COMPILE_CODE_SUCCESS,
    UPDATE_INTERVIEW_CODE_DETAILS,
    GET_INTERVIEW_QUESTIONS,
    GET_INTERVIEW_QUESTIONS_SUCCESS,
} from "./constants";

// The initial state of the App
const initialState = fromJS({
    interviewDetails: {
        interviewId: null,
        interviewDetailsFetched: false,
        interviewDetailsFetchError: null,
        interviewPermissions: null,
    },
    interviewCodeDetails: {},
    compilationOngoing: false,
    compileCodeResults: {},
    interviewQuestions: {
        data: {},
        fetched: true,
    },
});

function pairReducer(state = initialState, action) {
    switch (action.type) {
        case GET_INTERVIEW_QUESTIONS_SUCCESS:
            return state
                .setIn(
                    ["interviewQuestions", "data"],
                    fromJS(action.questions || {})
                )
                .setIn(["interviewQuestions", "fetched"], true);
        case GET_INTERVIEW_QUESTIONS:
            return state.setIn(["interviewQuestions", "fetched"], false);
        case INTERVIEW_DETAILS_SUCCESS:
            return state
                .setIn(["interviewDetails", "interviewId"], action.interviewId)
                .setIn(["interviewDetails", "interviewDetailsFetched"], true)
                .setIn(
                    ["interviewDetails", "interviewPermissions"],
                    action.permissions
                )
                .setIn(
                    ["interviewDetails", "interviewDetailsFetchError"],
                    null
                );
        case INTERVIEW_DETAILS_ERROR:
            return state
                .setIn(["interviewDetails", "interviewDetailsFetched"], false)
                .setIn(
                    ["interviewDetails", "interviewDetailsFetchError"],
                    action.error
                );
        case UPDATE_INTERVIEW_CODE_DETAILS:
            return state.setIn(
                ["interviewCodeDetails"],
                fromJS(action.details).set("code", action.code)
            );
        case COMPILE_CODE:
            return state.set("compilationOngoing", true);
        case COMPILE_CODE_SUCCESS:
            return state.set("compilationOngoing", false).set(
                "compileCodeResults",
                fromJS({
                    stdout: action.stdout,
                    stderr: action.stderr,
                    error: action.error,
                })
            );
        default:
            return state;
    }
}

export default pairReducer;
