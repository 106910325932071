import { fromJS, List } from 'immutable';

import {
    LOGIN_USER_SUCCESS,
} from './constants';

const initialState = fromJS({
    // companyId: null,
});


function candidateLoginPageReducer(state = initialState, action) {
    switch (action.type) {
    // case LOGIN_USER_SUCCESS:
    //     return state.set('companyId', action.companyId);
    default:
        return state;
    }
}

export default candidateLoginPageReducer;
