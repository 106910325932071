import React, { Children } from "react";
import PropTypes from "prop-types";
import Spinner from "react-spinkit";

export class LoadingIndicator extends React.PureComponent {
	// eslint-disable-line react/prefer-stateless-function

	render() {
		return (
			<div className="full-page-loader">
				<Spinner
					name="cube-grid"
					color="#00c5ff"
					className="actual-loader"
				/>
			</div>
		);
	}
}

export default LoadingIndicator;
