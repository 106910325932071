import {
	FETCH_DATA,
	FETCH_DATA_SUCCESS,
	SEND_DISCORD_MESSAGE,
} from "./constants";

export function fetchData({ emailId }) {
	return {
		type: FETCH_DATA,
		emailId,
	};
}

export function fetchDataSuccess({ emailId, data }) {
	return {
		type: FETCH_DATA_SUCCESS,
		emailId,
		data,
	};
}

export function sendDiscordMessage({ message }) {
	return {
		type: SEND_DISCORD_MESSAGE,
		message,
	};
}
