/**
 * Gets the repositories of the user from Github
 */

import getAPIDomain from "../../utils/domain";
import { put, select, takeEvery, throttle } from "redux-saga/effects";
import request from "../../utils/request";
import call from "../../utils/call";
import {
    fetchScorecardSuccess,
    fetchScorecardFailure,
    compileCodeSuccess,
    fetchScorecardInterviewDataSuccess,
    fetchScorecardIpDetailsSuccess,
} from "./actions";
import {
    FETCH_SCORECARD,
    SEND_DISCORD_MESSAGE,
    COMPILE_CODE,
    FETCH_SCORECARD_IP_DETAILS,
    FETCH_SCORECARD_INTERVIEW_DATA,
} from "./constants";
import { sendDiscordAlert } from "../../shared/discord";

function* sendDiscordMessage(action) {
    const { message } = action;

    sendDiscordAlert({
        isDev: process.env.NODE_ENV !== "production",
        message,
        channel: "DISCORD_LOGS_GENERIC_ALERTS_WEBHOOK",
    });
}

function* fetchScorecard(action) {
    const { env, interviewId, viewKey } = action;

    try {
        const scorecard = yield call(() =>
            request(
                getAPIDomain() +
                    "/app/api/integrations/dashboard/interview_scorecard",
                {
                    method: "POST",
                    body: JSON.stringify({
                        interviewId,
                        viewKey,
                    }),
                }
            )
        );

        yield put(
            fetchScorecardSuccess({
                interviewId,
                viewKey,
                scorecardData: scorecard,
                env,
            })
        );
    } catch (err) {
        console.log(err);
        yield put(
            fetchScorecardFailure({
                interviewId,
                env,
                viewKey,
            })
        );
    }
}

function* fetchScorecardInterviewData({ testId, env }) {
    NProgress && NProgress.start();

    const data = yield call(
        () =>
            request(
                getAPIDomain() +
                    "/app/api/integrations/dashboard/public_scorecard_interview_data",
                {
                    method: "POST",
                    body: JSON.stringify({
                        testId,
                        env,
                    }),
                }
            ),
        true
    );

    const { interviewData, status } = data;

    yield put(
        fetchScorecardInterviewDataSuccess({
            testId,
            interviewData,
            status,
            env,
        })
    );

    NProgress && NProgress.done();
}

function* fetchScorecardIpDetails({ interviewId, companyId, env, viewKey }) {
    NProgress && NProgress.start();

    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/ip_proctoring_details",
            {
                method: "POST",
                body: JSON.stringify({
                    interviewId,
                    companyId,
                    env,
                    viewKey,
                }),
            }
        )
    );
    if ((data || {}).success) {
        yield put(
            fetchScorecardIpDetailsSuccess({
                interviewId,
                viewKey,
                env,
                companyId,
                ipProctoringDetails: (data || {}).data,
            })
        );
    }
    NProgress && NProgress.done();
}

function* compileCode(action) {
    const { index, mode, code, modeVersion, filename, stdin, interviewId } =
        action;

    const data = {
        code,
        lang: mode,
        stdin,
    };

    // if (modeVersion) {
    // data["modeVersion"] = modeVersion;
    // }

    const url = getAPIDomain() + "/app/api/code/execute";

    // TODO: add fail safe to sphere

    console.log(url);
    const result = yield call(() =>
        request(url, {
            method: "POST",
            body: JSON.stringify(data),
        })
    );

    const { stdout, stderr, error } = result;
    const glotstdout = stdout;
    const glotstderr = stderr;
    const gloterror = error;

    yield put(
        compileCodeSuccess({
            interviewId,
            index,
            glotstdout,
            glotstderr,
            gloterror,
        })
    );
}

export default function* rootSaga() {
    // yield takeEvery(FETCH_SCORECARD, fetchScorecard);
    // yield takeEvery(FETCH_SCORECARD_IP_DETAILS, fetchScorecardIpDetails);
    yield takeEvery(
        FETCH_SCORECARD_INTERVIEW_DATA,
        fetchScorecardInterviewData
    );
    yield takeEvery(SEND_DISCORD_MESSAGE, sendDiscordMessage);
    yield takeEvery(COMPILE_CODE, compileCode);
}
