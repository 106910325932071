import { fromJS, List } from "immutable";

import {
	LOGIN_USER_SUCCESS,
	COMPANY_CREATION_SUCCESS,
	VERIFY_OTP_SUCCESS,
} from "./constants";

const initialState = fromJS({
	companies: [],
	loginSuccess: false,
	verifyOtpSuccess: false,
});

function dashboardLoginReducer(state = initialState, action) {
	switch (action.type) {
		case LOGIN_USER_SUCCESS:
			return state
				.set("companies", fromJS(action.companies))
				.set("loginSuccess", true);
		case COMPANY_CREATION_SUCCESS:
			return state
				.set("companies", fromJS([action.company]))
				.set("companyCreationSuccess", true);
		case VERIFY_OTP_SUCCESS:
			return state.set("verifyOtpSuccess", action.token);
		default:
			return state;
	}
}

export default dashboardLoginReducer;
