const flowVersion = "0.0.0";
window.lastServerTime = null;
window.lastDeviceTime = null;

function serverTimeCheck(serverTime) {
    // we just want to test for passing of time.
    if (!lastServerTime || !lastDeviceTime) {
        window.lastServerTime = serverTime;
        window.lastDeviceTime = Date.now();
        return {
            success: true,
        };
    } else {
        const diff = serverTime - lastServerTime;
        const currentDeviceTime = Date.now();
        const deviceDiff = currentDeviceTime - lastDeviceTime;
        if (Math.abs(deviceDiff - diff) > 1000 * 90) {
            return {
                success: false,
            };
        } else {
            window.lastServerTime = serverTime;
            window.lastDeviceTime = currentDeviceTime;
            return {
                success: true,
            };
        }
    }
}

function versionCheck(version) {
    if (flowVersion !== version) {
        return {
            success: false,
        };
    } else {
        return {
            success: true,
        };
    }
}

export { serverTimeCheck, versionCheck };
