import {
	FETCH_SCORECARD,
	FETCH_SCORECARD_SUCCESS,
	FETCH_SCORECARD_FAILURE,
	SEND_DISCORD_MESSAGE,
	COMPILE_CODE,
	COMPILE_CODE_SUCCESS,
	FETCH_SCORECARD_IP_DETAILS,
	FETCH_SCORECARD_IP_DETAILS_SUCCESS,
	FETCH_SCORECARD_INTERVIEW_DATA,
	FETCH_SCORECARD_INTERVIEW_DATA_SUCCESS,
} from "./constants";

export function fetchScorecard({ interviewId, viewKey, env }) {
	return {
		type: FETCH_SCORECARD,
		interviewId,
		viewKey,
		env,
	};
}

export function fetchScorecardSuccess({
	interviewId,
	viewKey,
	scorecardData,
	env,
}) {
	return {
		type: FETCH_SCORECARD_SUCCESS,
		interviewId,
		viewKey,
		scorecardData,
		env,
	};
}

export function fetchScorecardFailure({ intervewId, viewKey, env }) {
	return {
		type: FETCH_SCORECARD_FAILURE,
		env,
		intervewId,
		viewKey,
	};
}

export function fetchScorecardIpDetails({ interviewId, viewKey, env }) {
	return {
		type: FETCH_SCORECARD_IP_DETAILS,
		interviewId,
		viewKey,
		env,
	};
}

export function fetchScorecardIpDetailsSuccess({
	interviewId,
	viewKey,
	env,
	companyId,
	ipProctoringDetails,
}) {
	return {
		type: FETCH_SCORECARD_IP_DETAILS_SUCCESS,
		interviewId,
		viewKey,
		env,
		companyId,
		ipProctoringDetails,
	};
}

export function fetchScorecardInterviewData({ testId, env }) {
	return {
		type: FETCH_SCORECARD_INTERVIEW_DATA,
		testId,
		env,
	};
}

export function fetchScorecardInterviewDataSuccess({
	testId,
	env,
	interviewData,
	status,
}) {
	return {
		type: FETCH_SCORECARD_INTERVIEW_DATA_SUCCESS,
		testId,
		env,
		interviewData,
		status,
	};
}
export function sendDiscordMessage({ message }) {
	return {
		type: SEND_DISCORD_MESSAGE,
		message,
	};
}

export function compileCode({
	index,
	mode,
	code,
	stdin,
	modeVersion,
	filename,
	interviewId,
}) {
	return {
		type: COMPILE_CODE,
		index,
		mode,
		code,
		stdin,
		modeVersion,
		filename,
		interviewId,
	};
}

export function compileCodeSuccess({
	interviewId,
	index,
	glotstdout,
	glotstderr,
	gloterror,
}) {
	return {
		type: COMPILE_CODE_SUCCESS,
		interviewId,
		index,
		glotstdout,
		glotstderr,
		gloterror,
	};
}
