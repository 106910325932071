import React, {
    useEffect,
    memo,
    useState,
    useRef,
    forwardRef,
    useImperativeHandle,
} from "react";
import Select from "react-select";
import { fromJS } from "immutable";

const OTPField = forwardRef(({ submittedOTP, inputStyles }, ref) => {
    useImperativeHandle(ref, () => ({
        focus() {
            otpInputRefs[0].focus();
        },
    }));

    let [otp0, setOtp0] = useState("");
    let [otp1, setOtp1] = useState("");
    let [otp2, setOtp2] = useState("");
    let [otp3, setOtp3] = useState("");
    let [otp4, setOtp4] = useState("");
    let [otp5, setOtp5] = useState("");
    const otps = [otp0, otp1, otp2, otp3, otp4, otp5];
    const setOtps = [setOtp0, setOtp1, setOtp2, setOtp3, setOtp4, setOtp5];
    const otpInputRef0 = useRef(null);
    const otpInputRef1 = useRef(null);
    const otpInputRef2 = useRef(null);
    const otpInputRef3 = useRef(null);
    const otpInputRef4 = useRef(null);
    const otpInputRef5 = useRef(null);
    const otpInputRefs = [
        otpInputRef0,
        otpInputRef1,
        otpInputRef2,
        otpInputRef3,
        otpInputRef4,
        otpInputRef5,
    ];

    return [0, 1, 2, 3, 4, 5].map((i) =>
        i == 2 ? (
            <div style={{ display: "flex", alignItems: "center" }}>
                <input
                    onPaste={(e) => {
                        try {
                            let paste = (
                                e.clipboardData || window.clipboardData
                            ).getData("text");
                            paste = paste.toUpperCase();

                            if (paste.length === 6) {
                                // if all 6 characters of paste are integers, then proceed
                                let pasteIsValid = true;
                                for (let i = 0; i < paste.length; i++) {
                                    if (isNaN(parseInt(paste[i]))) {
                                        pasteIsValid = false;
                                        break;
                                    }
                                }
                                // convert each element of the paste string to integer
                                // each integer will go into the corresponding otps input using setOtps
                                // if all the paste characters are numbers, and acceptOtpState is not inprogress, then accept otp
                                if (pasteIsValid) {
                                    for (let i = 0; i < paste.length; i++) {
                                        setOtps[i](parseInt(paste[i]));
                                    }

                                    submittedOTP(paste);
                                }
                            }

                            e.preventDefault();
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                    value={otps[i]}
                    ref={otpInputRefs[i]}
                    onChange={(e) => {
                        let num = e.target.value;
                        if (!isNaN(num) && num !== " " && num && num !== "") {
                            num = num % 10;
                            setOtps[i](num);

                            if (i < 5) {
                                if (
                                    otpInputRefs[i + 1] &&
                                    otpInputRefs[i + 1].current
                                ) {
                                    otpInputRefs[i + 1].current.focus();
                                }
                            }

                            if (
                                otps.filter((v, ind) => {
                                    if (ind === i) {
                                        return (
                                            isNaN(num) ||
                                            num === "" ||
                                            num === " " ||
                                            num === null ||
                                            typeof num === undefined
                                        );
                                    } else {
                                        return (
                                            isNaN(otps[ind]) ||
                                            otps[ind] === "" ||
                                            otps[ind] === " " ||
                                            otps[ind] === null ||
                                            typeof otps[ind] === undefined
                                        );
                                    }
                                }).length === 0
                            ) {
                                submittedOTP(
                                    otps
                                        .map((v, ind) => {
                                            if (ind === i) {
                                                return num;
                                            } else {
                                                return otps[ind];
                                            }
                                        })
                                        .join("")
                                );
                            }
                        } else {
                            setOtps[i]("");
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.keyCode === 8) {
                            if (i > 0) {
                                setTimeout(() => {
                                    if (
                                        otpInputRefs[i - 1] &&
                                        otpInputRefs[i - 1].current
                                    ) {
                                        otpInputRefs[i - 1].current.focus();
                                    }
                                }, 200);
                            }
                        }
                    }}
                    id={`otp-input-${i}`}
                    className="otp-input-elem"
                    type="text"
                    required
                    placeholder=""
                    style={
                        {
                            textAlign: "center",
                            width: "80px",
                            height: "100px",
                            appearance: "none",
                            borderRadius:
                                i % 3 == 0
                                    ? "5px 0 0 5px"
                                    : i % 3 == 2
                                    ? "0 5px 5px 0"
                                    : "0px",
                            marginRight: i == 2 ? "10px" : "",
                            position: "relative",
                            padding: "10px 10px",
                            placeholderColor: "#ccc",
                            borderColor: "#a5a5a5",
                            borderWidth: i % 3 == 0 ? "1px" : "1px 1px 1px 0px",
                            borderStyle: "solid",
                            fontSize: "25px",
                        } || {}
                    }
                />
                <div
                    style={{
                        marginRight: "10px",
                        backgroundColor: "#a5a5a5",
                        height: "1px",
                        width: "10px",
                    }}
                ></div>
            </div>
        ) : (
            <input
                onPaste={(e) => {
                    try {
                        let paste = (
                            e.clipboardData || window.clipboardData
                        ).getData("text");
                        paste = paste.toUpperCase();

                        if (paste.length === 6) {
                            // if all 6 characters of paste are integers, then proceed
                            let pasteIsValid = true;
                            for (let i = 0; i < paste.length; i++) {
                                if (isNaN(parseInt(paste[i]))) {
                                    pasteIsValid = false;
                                    break;
                                }
                            }
                            // convert each element of the paste string to integer
                            // each integer will go into the corresponding otps input using setOtps
                            // if all the paste characters are numbers, and acceptOtpState is not inprogress, then accept otp
                            if (pasteIsValid) {
                                for (let i = 0; i < paste.length; i++) {
                                    setOtps[i](parseInt(paste[i]));
                                }

                                submittedOTP(paste);
                            }
                        }

                        e.preventDefault();
                    } catch (error) {
                        console.log(error);
                    }
                }}
                value={otps[i]}
                ref={otpInputRefs[i]}
                onChange={(e) => {
                    let num = e.target.value;
                    if (!isNaN(num) && num !== " " && num && num !== "") {
                        num = num % 10;
                        setOtps[i](num);

                        if (i < 5) {
                            if (
                                otpInputRefs[i + 1] &&
                                otpInputRefs[i + 1].current
                            ) {
                                otpInputRefs[i + 1].current.focus();
                            }
                        }

                        if (
                            otps.filter((v, ind) => {
                                if (ind === i) {
                                    return (
                                        isNaN(num) ||
                                        num === "" ||
                                        num === " " ||
                                        num === null ||
                                        typeof num === undefined
                                    );
                                } else {
                                    return (
                                        isNaN(otps[ind]) ||
                                        otps[ind] === "" ||
                                        otps[ind] === " " ||
                                        otps[ind] === null ||
                                        typeof otps[ind] === undefined
                                    );
                                }
                            }).length === 0
                        ) {
                            submittedOTP(
                                otps
                                    .map((v, ind) => {
                                        if (ind === i) {
                                            return num;
                                        } else {
                                            return otps[ind];
                                        }
                                    })
                                    .join("")
                            );
                        }
                    } else {
                        setOtps[i]("");
                    }
                }}
                onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                        if (i > 0) {
                            setTimeout(() => {
                                if (
                                    otpInputRefs[i - 1] &&
                                    otpInputRefs[i - 1].current
                                ) {
                                    otpInputRefs[i - 1].current.focus();
                                }
                            }, 200);
                        }
                    }
                }}
                id={`otp-input-${i}`}
                className="otp-input-elem"
                type="text"
                required
                placeholder=""
                style={
                    {
                        textAlign: "center",
                        width: "80px",
                        height: "100px",
                        appearance: "none",
                        borderRadius:
                            i % 3 == 0
                                ? "5px 0 0 5px"
                                : i % 3 == 2
                                ? "0 5px 5px 0"
                                : "0px",
                        marginRight: i == 2 ? "10px" : "",
                        position: "relative",
                        padding: "10px 10px",
                        placeholderColor: "#ccc",
                        borderColor: "#a5a5a5",
                        borderWidth: i % 3 == 0 ? "1px" : "1px 1px 1px 0px",
                        borderStyle: "solid",
                        fontSize: "25px",
                    } || {}
                }
            />
        )
    );
});

export default OTPField;
