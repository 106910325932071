/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
    COMPILE_CODE,
    COMPILE_CODE_SUCCESS,
    GET_INTERVIEW_DETAILS,
    INTERVIEW_DETAILS_SUCCESS,
    INTERVIEW_DETAILS_ERROR,
    SEND_SLACK_MESSAGE,
    TOGGLE_INTERVIEW_END,
    UPDATE_INTERVIEW_CODE_DETAILS,
    SEND_INVITE_EMAIL,
    REGISTER_CANDIDATE_DATA,
    GET_INTERVIEW_QUESTIONS,
    GET_INTERVIEW_QUESTIONS_SUCCESS,
} from "./constants";

export function getInterviewQuestionsSucess({ companyId, questions }) {
    return {
        type: GET_INTERVIEW_QUESTIONS_SUCCESS,
        companyId,
        questions,
    };
}

export function getInterviewQuestions({
    env,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
}) {
    return {
        env,
        limit,
        pageNo,
        filters,
        sortBy,
        companyId,
        type: GET_INTERVIEW_QUESTIONS,
    };
}

export function registerCandidateData({ name, interviewId }) {
    return {
        type: REGISTER_CANDIDATE_DATA,
        interviewId,
        name,
    };
}

export function sendInviteEmail({ email, interviewId }) {
    return {
        type: SEND_INVITE_EMAIL,
        email,
        interviewId,
    };
}

export function updateInterviewCodeDetails({ code, details, env }) {
    return {
        type: UPDATE_INTERVIEW_CODE_DETAILS,
        code,
        details,
        env,
    };
}

export function toggleInterviewEnd({ interviewId, endInterview }) {
    return {
        type: TOGGLE_INTERVIEW_END,
        interviewId,
        endInterview,
    };
}

export function getInterviewDetails(id) {
    return {
        type: GET_INTERVIEW_DETAILS,
        interviewId: id,
    };
}

export function interviewDetails({ interviewId, permissions }) {
    return {
        type: INTERVIEW_DETAILS_SUCCESS,
        interviewId,
        permissions,
    };
}

export function interviewDetailsError(error) {
    return {
        type: INTERVIEW_DETAILS_ERROR,
        error,
    };
}

export function compileCode({ mode, code, stdin }) {
    return {
        type: COMPILE_CODE,
        mode,
        code,
        stdin,
    };
}

export function compileCodeSuccess({ stdout, stderr, error }) {
    return {
        type: COMPILE_CODE_SUCCESS,
        stdout,
        stderr,
        error,
    };
}

export function sendSlackMessage({ message, channel }) {
    return {
        type: SEND_SLACK_MESSAGE,
        message,
        channel,
    };
}
