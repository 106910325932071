import { API_ERROR, ERROR_CODES } from "./constants";

const handleGlobalActions = (state, action) => {
    switch (action.type) {
        case API_ERROR: {
            return state.set(
                "__global__requestError",
                ERROR_CODES[action.errorCode] || null
            );
        }
        default:
            return state;
    }
};

export default handleGlobalActions;
