/*
 * ChatConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const GET_INTERVIEW_DETAILS = "cui/PairSession/GET_INTERVIEW_DETAILS";
export const INTERVIEW_DETAILS_SUCCESS =
    "cui/PairSession/INTERVIEW_DETAILS_SUCCESS";
export const INTERVIEW_DETAILS_ERROR =
    "cui/PairSession/INTERVIEW_DETAILS_ERROR";
export const SEND_SLACK_MESSAGE = "cui/PairSession/SEND_SLACK_MESSAGE";
export const COMPILE_CODE = "cui/PairSession/COMPILE_CODE";
export const COMPILE_CODE_SUCCESS = "cui/PairSession/COMPILE_CODE_SUCCESS";
export const UPDATE_INTERVIEW_CODE_DETAILS =
    "cui/PairSession/UPDATE_INTERVIEW_CODE_DETAILS";
export const TOGGLE_INTERVIEW_END = "cui/PairSession/TOGGLE_INTERVIEW_END";
export const SEND_INVITE_EMAIL = "cui/PairSession/SEND_INVITE_EMAIL";
export const REGISTER_CANDIDATE_DATA =
    "cui/PairSession/REGISTER_CANDIDATE_DATA";
export const GET_INTERVIEW_QUESTIONS =
    "cui/PairSession/GET_INTERVIEW_QUESTIONS";
export const GET_INTERVIEW_QUESTIONS_SUCCESS =
    "cui/PairSession/GET_INTERVIEW_QUESTIONS_SUCCESS";
