/*
 * ChatConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const GET_INTERVIEW_DETAILS = "cui/Flow/GET_INTERVIEW_DETAILS";
export const INTERVIEW_DETAILS_SUCCESS = "cui/Flow/INTERVIEW_DETAILS_SUCCESS";
export const INTERVIEW_DETAILS_ERROR = "cui/Flow/INTERVIEW_DETAILS_ERROR";
export const FETCH_PREVIOUS_MESSAGES = "cui/Flow/FETCH_PREVIOUS_MESSAGES";
export const FETCH_PREVIOUS_MESSAGES_SUCCESS =
    "cui/Flow/FETCH_PREVIOUS_MESSAGES_SUCCESS";
export const FETCH_PREVIOUS_MESSAGES_ERROR =
    "cui/Flow/FETCH_PREVIOUS_MESSAGES_ERROR";
export const FETCH_MESSAGES_SINCE = "cui/Flow/FETCH_MESSAGES_SINCE";
export const FETCH_MESSAGES_SINCE_SUCCESS =
    "cui/Flow/FETCH_MESSAGES_SINCE_SUCCESS";
export const FETCH_MESSAGES_SINCE_ERROR = "cui/Flow/FETCH_MESSAGES_SINCE_ERROR";
export const SEND_MESSAGES = "cui/Flow/SEND_MESSAGES";
export const SEND_MESSAGES_SUCCESS = "cui/Flow/SEND_MESSAGES_SUCCESS";
export const SEND_MESSAGES_ERROR = "cui/Flow/SEND_MESSAGES_ERROR";
export const SEND_SLACK_MESSAGE = "cui/Flow/SEND_SLACK_MESSAGE";
export const ADD_MESSAGE_TO_BUFFER = "cui/Flow/ADD_MESSAGE_TO_BUFFER";
export const UPDATE_DATASTORE = "cui/Flow/UPDATE_DATASTORE";
export const UPDATE_DECRYPTIONKEY = "cui/Flow/UPDATE_DECRYPTIONKEY";
export const UPDATE_INTERVIEW = "cui/Flow/UPDATE_INTERVIEW";
export const LATENCY_ERROR = "cui/Flow/LATENCY_ERROR";
export const SEND_DISCORD_MESSAGE = "cui/Flow/SEND_DISCORD_MESSAGE";

export const ERROR_CODES = {
    ECONNABORTED: "ERR_ABORTED",
    ETIMEDOUT: "ERR_TIMEDOUT",
    ERR_NETWORK: "ERR_BAD_NETWORK",
    ERR_BAD_RESPONSE: "ERR_BAD_RESPONSE",
};
