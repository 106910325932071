import { call } from "redux-saga/effects";
import request from "./request";
import { openDB } from "idb";

function cachedRequest(url, options, timeoutMs = 40000) {
    return request(url, options, timeoutMs, true);
}

const dbPromise = openDB("myDB", 1, {
    upgrade(db) {
        db.createObjectStore("etag-store");
    },
});

async function etagHandler(
    response,
    etagName,
    etagDataName,
    isDataAnObject = true
) {
    const db = await dbPromise;

    if (response?.status === 304) {
        const cachedData = await db.get("etag-store", etagDataName);
        return isDataAnObject ? JSON.parse(cachedData) : cachedData;
    } else if (response?.status === 200) {
        const output = response.data;
        await db.put(
            "etag-store",
            isDataAnObject ? JSON.stringify(output) : output,
            etagDataName
        );
        if (response?.headers?.get("etag")) {
            await db.put("etag-store", response.headers.get("etag"), etagName);
        }
        return output;
    }
}

export function* fetchCachedAPI({
    url,
    etagName,
    etagDataName,
    defaultData = {},
}) {
    let data = defaultData;

    try {
        const db = yield call(() => dbPromise);
        const cachedEtag = yield call(() => db.get("etag-store", etagName));

        const response = yield call(() =>
            cachedRequest(url, {
                method: "POST",
                headers: {
                    "If-None-Match": cachedEtag || "",
                },
            })
        );

        data = yield call(() =>
            etagHandler(response, etagName, etagDataName, true)
        );
    } catch (err) {
        console.error("Error fetching cached API", err);
    }

    return data;
}
