/**
 * The global state selectors
 */

import { createSelector } from "reselect";
import { select } from "redux-saga/effects";

const selectRoute = (state) => state.get("route");

const makeSelectLocation = () =>
	createSelector(selectRoute, (routeState) =>
		routeState.get("location").toJS()
	);

const selectEnv = (state) => state.get("env");

const makeSelectEnv = () =>
	createSelector(selectEnv, (envState) => envState.get("env"));

export { makeSelectLocation, makeSelectEnv };
