import { fromJS } from "immutable";

import {
	GET_INTERVIEW_PUBLIC_DATA,
	GET_INTERVIEW_PUBLIC_DATA_SUCCESS,
} from "./constants";

// The initial state of the App
const initialState = fromJS({
	loading: false,
	apiStartTime: 0,
});

function chatStartReducer(state = initialState, action) {
	switch (action.type) {
		case GET_INTERVIEW_PUBLIC_DATA:
			return state.set("loading", false).set("apiStartTime", Date.now());
		case GET_INTERVIEW_PUBLIC_DATA_SUCCESS:
			return state
				.set("loaded", true)
				.set("data", fromJS(action.data || {}));
		default:
			return state;
	}
}

export default chatStartReducer;
