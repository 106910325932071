export const questions = [
    {
        displayString: "Introduction",
        dtreeNode: {
            text: "Let's start once you are ready!",
            expect: "mcq",
            choices: [
                {
                    choice: "done",
                    display: "Done.",
                    allowText: true,
                    hideOnClick: true,
                },
            ],
        },
    },
    {
        displayString: "Can you write efficient code for searching an element in a sorted array?",
        dtreeNode: {
            text: "Let's solve local minima questions for the win!!!",
            expect: "mcq",
            choices: [
                {
                    choice: "done",
                    display: "Done.",
                    allowText: true,
                    hideOnClick: true,
                },
            ],
        },
    },
    {
        displayString: "How do you create a Queue functionality using two Stacks?",
        dtreeNode: {
            text: "Am awesomeeeeee!!!!! Ua ua ua",
            expect: "mcq",
            choices: [
                {
                    choice: "done",
                    display: "Done.",
                    allowText: true,
                    hideOnClick: true,
                },
            ],
        },
    },
    {
        displayString: "Can you explain how to find if a linked list has a loop?",
        dtreeNode: {
            text: "Let's solve local minima questions for the win!!!",
            expect: "mcq",
            choices: [
                {
                    choice: "done",
                    display: "Done.",
                    allowText: true,
                    hideOnClick: true,
                },
            ],
        },
    },
    {
        displayString: "How do you design an efficient login system?",
        dtreeNode: {
            text: "Am awesomeeeeee!!!!! Ua ua ua",
            expect: "mcq",
            choices: [
                {
                    choice: "done",
                    display: "Done.",
                    allowText: true,
                    hideOnClick: true,
                },
            ],
        },
    },
];
