import { fromJS, List } from "immutable";

import {
	LOGIN_USER_SUCCESS,
	SEND_OTP_SUCCESS,
	VERIFY_OTP_SUCCESS,
} from "./constants";

const initialState = fromJS({
	// companyId: null,
	verifyOtpSuccess: false,
	sendOtpSuccess: "",
});

function candidateLoginPageReducer(state = initialState, action) {
	switch (action.type) {
		// case LOGIN_USER_SUCCESS:
		//     return state.set('companyId', action.companyId);
		case VERIFY_OTP_SUCCESS:
			return state.set("verifyOtpSuccess", action.token);
		case SEND_OTP_SUCCESS:
			return state.set(
				"sendOtpSuccess",
				action.success ? "success" : "failed"
			);
		default:
			return state;
	}
}

export default candidateLoginPageReducer;
