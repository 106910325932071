import {
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	RESEND_OTP,
	SEND_OTP,
	SEND_OTP_SUCCESS,
	VERIFY_OTP,
	VERIFY_OTP_SUCCESS,
} from "./constants";

export function sendOtp({ emailId }) {
	return {
		type: SEND_OTP,
		emailId,
	};
}

export function sendOtpSuccess({ success }) {
	return {
		type: SEND_OTP_SUCCESS,
		success,
	};
}

export function resendOtp({ emailId }) {
	return {
		type: RESEND_OTP,
		emailId,
	};
}

export function verifyOtp({ emailId, code }) {
	return {
		type: VERIFY_OTP,
		emailId,
		code,
	};
}

export function verifyOtpSuccess({ emailId, token }) {
	return {
		type: VERIFY_OTP_SUCCESS,
		emailId,
		token,
	};
}
// export function loginUser({
//     emailId,
//     tokenId,
//     env,
// }) {
//     return {
//         type: LOGIN_USER,
//         emailId,
//         tokenId,
//         env,
//     }
// }

// export function loginUserSuccess({
//     candidateId
// }) {
//     return {
//         type: LOGIN_USER_SUCCESS,
//         candidateId,
//     }
// }
