/**
 * Gets the repositories of the user from Github
 */

import { call, put, select, takeEvery, throttle } from "redux-saga/effects";
import { delay } from "redux-saga";
import { fromJS } from "immutable";

import request from "../../utils/request";
import Events from "../../utils/events";
import { FETCH_DATA, SEND_DISCORD_MESSAGE } from "./constants";

import { fetchDataSuccess } from "./actions";
import { sendDiscordAlert } from "../../shared/discord";

import getAPIDomain from "../../utils/domain";

function* sendDiscordMessage(action) {
    const { message } = action;

    sendDiscordAlert({
        isDev: process.env.NODE_ENV !== "production",
        message,
        channel: "DISCORD_LOGS_GENERIC_ALERTS_WEBHOOK",
    });
}

function* fetchData(action) {
    const { emailId } = action;
    const data = yield call(() =>
        request(getAPIDomain() + "/app/api/user/account", {
            method: "POST",
            body: JSON.stringify({
                emailId,
            }),
        })
    );

    yield put(
        fetchDataSuccess({
            data,
        })
    );
}

export default function* rootSaga() {
    yield takeEvery(FETCH_DATA, fetchData);
    yield takeEvery(SEND_DISCORD_MESSAGE, sendDiscordMessage);
}
