const devDiscordUrl =
    "https://discord.com/api/webhooks/961839990877331527/I9seQHiAazNMpL8hR6-85O_A8p-Lp-bh3W6d0RZLOOm17RFmPJ-BeGG23Uu78W6EogZU";
const prodDiscordUrl = {
    DISCORD_SUCCESS_WEBHOOK:
        "https://discord.com/api/webhooks/961113689870700574/SMudEmKxlKS4hBnVrM18JqDS9gWHKg34Gt8mUyTXwGqFun5amMo7woVjw2j18HpJ7KKD",
    DISCORD_ERRORS_WEBHOOK:
        "https://discord.com/api/webhooks/961114294324437012/KGJW3AtNSXuxorip7ssrx9o6zTt_P0EnztdMbYE1N3jfatEt-kqJwC6-R1JJs9b0GX7T",
    DISCORD_ALERTS_WEBHOOK:
        "https://discord.com/api/webhooks/961113989167861770/uxJwSuppX0LnG7IK1y9En_5vc4BoqV32zzDDEBDtCBWU_NqKWSDoaVssX6hjaw5DM3LV",
    DISCORD_DIGEST_WEBHOOK:
        "https://discord.com/api/webhooks/961114165982920736/xZegXN4z4WcL2T2QQhjQsPfOrsnoanihVHMcEoMfba_7TnDmLIGRONiKm2iJKysRg_n4",
    DISCORD_LOGS_COMPANIES_CORE_WEBHOOK:
        "https://discord.com/api/webhooks/1096023786161193000/8QAe5SJ-NNW9pT5j01QwDgvRA7IVD0zDhKElkC2yORpRTw0O6pjXFh_Nhf2UckVkhOyB",
    DISCORD_LOGS_TALK_REQUESTS_WEBHOOK:
        "https://discord.com/api/webhooks/1096023913059856466/b9YCbw5bRD9gjHHYEXQFxrz1GX39Y6u7rx02g6PiBFLMfEXhn7C1_HbvdtKy-8KL_wEa",
    DISCORD_LOGS_COMPANIES_PLANS_WEBHOOK:
        "https://discord.com/api/webhooks/1096024047210475570/7p2Cvh-_cjYaVVhUxC5OHSDh7b1l4cwMEiyEzCF93gMvoJC_8CGdQWesIoNNmavfZ9ZR",
    DISCORD_LOGS_PREVIEW_TEST_WEBHOOK:
        "https://discord.com/api/webhooks/1096024142152728687/XVWHWCCNwawhCtgYyoC2glUr7jVT2iwgbqiQpy9ecGsutSgQSBcYX8s0NdHsLMUctD4X",
    DISCORD_LOGS_TEST_REQUESTS_WEBHOOK:
        "https://discord.com/api/webhooks/1096024245278081054/7m7Q7S1c2NkgbwEIG5UAzDL870aO-8Vh8CjEeNuvXI1y2yeqCRqWS13pkZ_zW62EzGLb",
    DISCORD_LOGS_COMPANIES_NEW_WEBHOOK:
        "https://discord.com/api/webhooks/1096024338156769320/_8N9SeJzx4_fQTDSLlosgvWaQOu5y1MAIjlRUSW1Ot7GLNmaKE-QGxI0bcodxdGdLRJ_",
    DISCORD_LOGS_RATINGS_WEBHOOK:
        "https://discord.com/api/webhooks/1096024441412128769/raIFHjLuH5Frrz-fYYahZM1_p0qPI-MKmbRvCeBOjJ5pC9WOfkvKJJjeWbRNzfDa9GXi",
    DISCORD_LOGS_TEST_CHANGES_WEBHOOK:
        "https://discord.com/api/webhooks/1096024534613766205/lxG6WsuwgolnIVYr6NCrSgL26cFpASTQfhwF4OTZaIQbz4Xm8y4Wzo7a820T_EGQ_hAr",
    DISCORD_LOGS_SUCCESS_FACTORS_WEBHOOK:
        "https://discord.com/api/webhooks/1096024685751320626/2Vu-lZ0y6PCDkS29kJa_ez8_aG4CKb24hrpTaUCnyibNRwWVpOlKfXEIGGqfN_6vceAg",
    DISCORD_TEMP_CODING_TIMEOUTS_WEBHOOK:
        "https://discord.com/api/webhooks/1096024836846927964/ALteXGpBbOChwtf3An9LaapVRGEwauldE70EBBekIu9I51hOcWaRHZYZvfEDEtb1Zoo4",
    DISCORD_LOGS_GENERIC_ALERTS_WEBHOOK:
        "https://discord.com/api/webhooks/" +
        "1208992181491208312/" +
        "diPeWai3J6u00V_kRkXL3RPVK8wEcdE1mHk-MWZVs8cW2v4ario5gbTqD35kN1OWm_qg",
};

function sendDiscordErrorAlert({ isDev, message, data }) {
    if (message) {
        return fetch(
            isDev ? devDiscordUrl : prodDiscordUrl["DISCORD_ERRORS_WEBHOOK"],
            {
                method: "POST",
                body: JSON.stringify({
                    content: `🛑️ ${(message || "").substring(0, 1950)}`,
                    ...(data
                        ? {
                              embeds: [
                                  {
                                      description: JSON.stringify(
                                          data,
                                          null,
                                          4
                                      ),
                                  },
                              ],
                          }
                        : {}),
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "omit",
                withCredentials: false,
            }
        );
    }
}

function sendDiscordAlert({ isDev, message, data, channel }) {
    if (message) {
        try {
            fetch(isDev ? devDiscordUrl : prodDiscordUrl[channel], {
                method: "POST",
                body: JSON.stringify({
                    content: `📓 ${(message || "").substring(0, 1950)}`,
                    ...(data
                        ? {
                              embeds: [
                                  {
                                      description:
                                          "```json\n" +
                                          JSON.stringify(data, null, 4) +
                                          "\n```",
                                  },
                              ],
                          }
                        : {}),
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "omit",
                withCredentials: false,
            });
        } catch (e) {
            console.log(e);
        }
    }
}

function sendMajorAlert({ message, data, channel }) {
    if (message) {
        let discordChannel = "DISCORD_LOGS_GENERIC_ALERTS_WEBHOOK";
        if (channel === "logs-companies-new") {
            discordChannel = "DISCORD_LOGS_COMPANIES_NEW_WEBHOOK";
        } else if (channel === "logs-ratings") {
            discordChannel = "DISCORD_LOGS_RATINGS_WEBHOOK";
        } else if (channel === "logs-success-factors") {
            discordChannel = "DISCORD_LOGS_SUCCESS_FACTORS_WEBHOOK";
        } else if (channel === "logs-companies-plans") {
            discordChannel = "DISCORD_LOGS_COMPANIES_PLANS_WEBHOOK";
        } else if (channel === "temp-coding-timeouts") {
            discordChannel = "DISCORD_TEMP_CODING_TIMEOUTS_WEBHOOK";
        } else if (channel === "logs-test-requests") {
            discordChannel = "DISCORD_LOGS_TEST_REQUESTS_WEBHOOK";
        } else if (channel === "logs-test-changes") {
            discordChannel = "DISCORD_LOGS_TEST_CHANGES_WEBHOOK";
        }

        sendDiscordAlert({
            isDev: process.env.NODE_ENV === "development",
            message,
            data,
            channel: discordChannel,
        });
    }
}

export { sendDiscordErrorAlert, sendDiscordAlert, sendMajorAlert };
