export const ERROR_CODES = {
    ECONNABORTED: "ERR_ABORTED",
    ETIMEDOUT: "ERR_TIMEDOUT",
    ERR_NETWORK: "ERR_BAD_NETWORK",
    ERR_BAD_RESPONSE: "ERR_BAD_RESPONSE",
    ERR_NETWORK_IO_SUSPENDED: "ERR_NETWORK_IO_SUSPENDED",
    ERR_FR_TOO_MANY_REDIRECTS: "ERR_FR_TOO_MANY_REDIRECTS",
    ERR_DEPRECATED: "ERR_DEPRECATED",
    ERR_CANCELED: "ERR_CANCELED",
};

export const API_ERROR = "API_ERROR";
