import Proctoring from "./Proctoring";

export default class FullscreenProctoring extends Proctoring {
	constructor(env) {
		super(env);
	}

	setup() {
		//Check if the user is in full screen mode
		this.interval = setInterval(() => {
			const isFullScreen =
				document.fullscreenElement ||
				document.webkitFullscreenElement ||
				document.mozFullScreenElement ||
				document.msFullscreenElement ||
				window.innerHeight == screen.height;
			const previousFullScreen = this.fullScreen;

			if (this.firstTimeFullScreenValue) {
				if (previousFullScreen !== isFullScreen) {
					// change in full screen
					this.fullScreen = isFullScreen;
					this.subscribers.map((x) => x(this.fullScreen));
				}
			} else if (isFullScreen) {
				this.firstTimeFullScreenValue = true;
				this.fullScreen = isFullScreen;
				this.subscribers.map((x) => x(this.fullScreen, true));
			}
		}, 300);
	}
}
