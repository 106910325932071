// src/Auth/Auth.js
import _ from "lodash";
import request from "./request";
// import Auth0Lock from 'auth0-lock';
import { sendErrorToast, sendToast } from "./toaster";
import Events from "./events";
import { sendDiscordAlert } from "../shared/discord";
import getAPIDomain from "./domain";

function getDomain() {
    if (process.env.NODE_ENV === "development") {
        return "localhost";
    }

    return ".adaface.com";
}

export default class Auth {
    constructor(env) {
        this.login = this.login.bind(this);
        this.subscribers = [];
        this.reset(true);
    }

    reset(page, useRedirect, container, options) {
        this.page = page;
        this.container = container;
    }

    subscribe(callback) {
        this.subscribers.push(callback);
        return () => {
            this.subscribers.splice(this.subscribers.indexOf(callback), 1);
        };
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    doTokenAuthentication(adafaceToken) {
        this.setToken(adafaceToken);
        this.subscribers.map((x) => x());
        if (this.page) {
            Events.sendAction({
                action: Events.ACTIONS.GENERAL,
                name: "auth0success",
                page: this.page,
            });
        }
    }

    _doAuthenticationError(error) {
        console.log(error);
        if (window.location.href.indexOf("/app/dashboard/login") !== -1) {
            sendErrorToast(error.errorDescription, null);
        }
        if (this.page) {
            Events.sendAction({
                action: Events.ACTIONS.GENERAL,
                name: "auth0error",
                page: this.page,
                metadata: {
                    error: (error || {}).errorDescription || "",
                },
            });
        }
    }

    login(url, options) {
        const mergedOptions = options || {};
        const auth = mergedOptions.auth || {};
        const params = auth.params || {};
        params.state = url || window.location.href;
        auth.params = params;
        mergedOptions.auth = auth;

        // this.lock.show(mergedOptions);
        if (this.page) {
            Events.sendAction({
                action: Events.ACTIONS.GENERAL,
                name: "auth0view",
                page: this.page,
            });
        }
    }

    scheduleRenewal() {
        // if (this.expiresAt) {
        //     let expiresAt = this.expiresAt;
        //     const timeout = expiresAt - Date.now();
        //     if (timeout > 0) {
        //         // cancel old one
        //         if (this.tokenRenewalTimeout) {
        //             clearTimeout(this.tokenRenewalTimeout);
        //         }
        //       this.tokenRenewalTimeout = setTimeout(() => {
        //         this.renewSession();
        //       }, timeout);
        //     }
        // }
    }

    getExpiryDate() {
        return JSON.stringify(new Date(this.expiresAt));
    }

    renewSession(doLater) {
        // this.lock.checkSession({redirectUri: window.location.origin}, (err, authResult) => {
        //     if (authResult && authResult.accessToken && authResult.idToken) {
        //         // console.log(authResult);
        //         // this._doAuthentication(authResult);
        //         if (doLater) {
        //             doLater();
        //         }
        //       } else if (err) {
        //         // This is doing weird stuff. so for now, let the general flow take care of this case.
        //         // this.logout();
        //         console.log(err);
        //         if (doLater) {
        //             doLater();
        //         }
        //       }
        // })
    }

    loggedIn() {
        // console.log("check if logged in", this.getToken())
        return !!this.getToken();
    }

    setToken(idToken) {
        // localStorage.setItem("id_token", idToken);
        // TODO: (SG) this setting needs to happen by backend + on base domain so that it is accessible to all root domains
        // And remove need of getToken on frontend from everywhere
        this.setCookie("adaface_uuid", idToken, 365);
    }

    getToken() {
        // return localStorage.getItem("id_token");
        return this.getCookie("adaface_uuid");
    }

    setCookie(name, value, days) {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires=${date.toUTCString()}`;
        }
        document.cookie = `${name}=${value || ""}${expires};${
            window.location.href.indexOf("localhost") === -1
                ? "domain=.adaface.com;"
                : ""
        } path=/; secure`;
    }

    getCookie(name) {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    eraseCookie(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${getDomain()}; secure`;
    }

    logout(inp) {
        const { force, url } = inp || {};

        // console.log("am here?");
        // localStorage.removeItem("id_token");
        this.eraseCookie("adaface_uuid");
        // if (this.tokenRenewalTimeout) {
        // clearTimeout(this.tokenRenewalTimeout);
        // }

        if (force) {
            if (url) {
                // console.log("am here");
                // this.lock.logout({
                // returnTo: url,
                // });
            } else {
                // this.lock.logout();
            }
        }

        this.subscribers.map((x) => x());
        if (this.page) {
            Events.sendAction({
                action: Events.ACTIONS.GENERAL,
                name: "auth0logout",
                page: this.page,
            });
        }
    }

    getProfile(callback) {
        // console.log("asking for profile", this.loggedIn(), this.getToken());
        if (this.loggedIn()) {
            request(getAPIDomain() + "/app/api/user/profile", {
                method: "POST",
                body: JSON.stringify({
                    adafaceToken: this.getToken(),
                }),
            })
                .then(({ profile, emailId }) => {
                    // console.log(profile);

                    callback(profile);
                    if (this.page) {
                        Events.sendAction({
                            action: Events.ACTIONS.GENERAL,
                            name: "auth0profilesuccess",
                            page: this.page,
                        });

                        if (
                            this.getCookie("TIMON_UNIQUE_USER_ID") !==
                            profile.email
                        ) {
                            const prevId = this.getCookie(
                                "TIMON_UNIQUE_USER_ID"
                            );
                            this.setCookie(
                                "TIMON_UNIQUE_USER_ID",
                                profile.email,
                                365
                            );

                            // console.log("DDD");
                            sendDiscordAlert({
                                isDev: process.env.NODE_ENV !== "production",
                                message: `${prevId} changed to ${profile.email}`,
                                channel: "DISCORD_LOGS_COMPANIES_CORE_WEBHOOK",
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.logout();
                    if (this.page) {
                        Events.sendAction({
                            action: Events.ACTIONS.GENERAL,
                            name: "auth0profileerror",
                            page: this.page,
                            metadata: {
                                error,
                            },
                        });
                    }
                });
        }
    }
}
