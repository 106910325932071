import { combineReducers } from "redux-immutable";
import { fromJS } from "immutable";
// import reducer.js from all ../containers/*/reducer.js
const context = require.context("../containers", true, /reducer\.js$/);

const reducers = context.keys().reduce((result, key) => {
    const reducer = context(key).default;
    const reducerName = key.replace(/\.\/(.*)\/reducer\.js/, "$1");
    result[reducerName] = reducer;
    return result;
}, {});

// Initial routing state
const routeInitialState = fromJS({
    location: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}

function envReducer(state = fromJS({ env: process.env.NODE_ENV }), action) {
    return state;
}

const rootReducer = combineReducers({
    route: routeReducer,
    env: envReducer,
    // ... other reducers
    ...reducers,
});

export default rootReducer;
