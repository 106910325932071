export const FETCH_SCORECARD = "cui/PublicScorecard/FETCH_SCORECARD";
export const FETCH_SCORECARD_SUCCESS =
    "cui/PublicScorecard/FETCH_SCORECARD_SUCCESS";
export const FETCH_SCORECARD_FAILURE =
    "cui/PublicScorecard/FETCH_SCORECARD_FAILURE";
export const FETCH_SCORECARD_INTERVIEW_DATA =
    "cui/PublicScorecard/FETCH_SCORECARD_INTERVIEW_DATA";
export const FETCH_SCORECARD_INTERVIEW_DATA_SUCCESS =
    "cui/PublicScorecard/FETCH_SCORECARD_INTERVIEW_DATA_SUCCESS";

export const FETCH_SCORECARD_IP_DETAILS =
    "cui/PublicScorecard/FETCH_SCORECARD_IP_DETAILS";
export const FETCH_SCORECARD_IP_DETAILS_SUCCESS =
    "cui/PublicScorecard/FETCH_SCORECARD_IP_DETAILS_SUCCESS";

export const SEND_DISCORD_MESSAGE = "cui/PublicScorecard/SEND_DISCORD_MESSAGE";
export const COMPILE_CODE = "cui/PublicScorecard/COMPILE_CODE";
export const COMPILE_CODE_SUCCESS = "cui/PublicScorecard/COMPILE_CODE_SUCCESS";
