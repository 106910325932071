import { createSelector } from "reselect";
import { List } from "immutable";

const candidateGlobal = (state) => state.get("CandidateLoginPage");

const makeSelectVerifyOtpSuccess = () =>
    createSelector(candidateGlobal, (state) => state.get("verifyOtpSuccess"));
const makeSelectSendOtpSuccess = () =>
    createSelector(candidateGlobal, (state) => state.get("sendOtpSuccess"));

// const makeSelectCompanyId = () => createSelector(
//     dashboardGlobal,
//     (state) => state.get('companyId')
// )

export {
    // makeSelectCompanyId,
    makeSelectVerifyOtpSuccess,
    makeSelectSendOtpSuccess,
};
